import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from './Account'

const Status = () => {
    const [status, setStatus] = useState("")
    const { getSession, logout } = useContext(AccountContext)

    useEffect(() => {
        getSession().then((session) => {
            setStatus(true)
        }).catch(e => {
            console.log(e);
        });
    }, [])

    return (
        <div>{status ?
            <button onClick={logout} className="btn btn-danger">Cerrar sesión</button> :
            ""
        }</div>
    )
}

export default Status