import React from "react";
// import Signup from "../../components/Auth/Signup";
import { Account } from "../../components/Auth/Account"
import { ChangePassword } from "../../components/Auth";

const AuthChangePassword = () => {
    return (
        <Account>
            <div className="container">
                <div className="row">
                    <header className="App-header">
                        <h1>Cambiar contraseña</h1>
                    </header>
                    <ChangePassword />
                </div>
            </div>
        </Account>
        
    );
};

export default AuthChangePassword;
