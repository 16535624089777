import { useState, useEffect } from "react";

const useThemeMode = () => {
    const [theme, setTheme] = useState("light");

    useEffect(() => {
        const themeOS =
            window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: dark)").matches
                ? "dark"
                : "light";
        const localTheme = localStorage.getItem("theme");
        themeOS && !localTheme
            ? setTheme(themeOS)
            : localTheme && setTheme(localTheme);
    }, []);

    const toggleTheme = () => {
        if (theme === "light") {
            localStorage.setItem("theme", "dark");
            setTheme("dark");
            return;
        }

        localStorage.setItem("theme", "light");
        setTheme("light");
    };

    return [theme, toggleTheme];
};

export default useThemeMode;