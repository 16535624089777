import React, { useState, useContext } from "react";
import { AccountContext } from '../Account'
import { useLocation } from 'react-router-dom';
import "./style.css"; // Estilos del componente

const ChangePassword = () => {
    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const { changePass, userAuth } = useContext(AccountContext)
    const { state } = useLocation();


    const onSubmit = (e) => {
        const userMail = state.data.email;
        e.preventDefault();
        changePass(userMail, newPassword).then(({ user }) => {
            user.changePassword(password, newPassword, (err, result) => {
                if (err) { console.error(err); }
                else { console.log("Contraseña actualizada: ", result) }
            })
        }).catch(e => {
            console.log(e);
        });
    }

    const toggleShowPassword = (idInput, idEye) => {
        const passwordInput = document.getElementById(idInput);
        const togglePassword = document.getElementById(idEye);

        if (passwordInput.type === "password") {
            passwordInput.type = "text";
            togglePassword.classList.remove("fa-eye-slash");
            togglePassword.classList.add("fa-eye");
        } else {
            passwordInput.type = "password";
            togglePassword.classList.remove("fa-eye");
            togglePassword.classList.add("fa-eye-slash");
        }
    }

    return (
        <div className="card">
            <h2 className="text-center mb-4">Actualizar contraseña</h2>
            <form className="form-card" onSubmit={onSubmit}>
                <div className="row justify-content-between text-left">
                    <div className="form-group col-md-12 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="passwordNewPass">Contraseña actual<span className="text-danger"> *</span></label>
                        <div className="input-group" id="show_hide_password">
                            <input type="password" id="passwordNewPass" name="passwordNewPass" placeholder=""
                                className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <div id="contTogglePassword" className="input-group-addon">
                                <i id="togglePasswordNewPass" className="fa fa-eye-slash" aria-hidden="true"
                                    onClick={() => toggleShowPassword("passwordNewPass", "togglePasswordNewPass")}></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-between text-left">
                    <div className="form-group col-md-12 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="newPassword">Nueva contraseña<span className="text-danger"> *</span></label>
                        <div className="input-group" id="show_hide_password">
                            <input type="password" id="newPassword" name="newPassword" placeholder=""
                                className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            <div id="contTogglePassword" className="input-group-addon">
                                <i id="toggleNewPasswordNewPass" className="fa fa-eye-slash" aria-hidden="true"
                                    onClick={() => toggleShowPassword("newPassword", "toggleNewPasswordNewPass")}></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="text-center mt-4">
                        <button type="submit" className="btn btn-success">Cambiar contraseña</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ChangePassword