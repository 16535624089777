import React, { useState } from "react";
import UserPool from "../../../middlewares/UserPool";
import "./style.css"; // Estilos del componente

const Signup = () => {
    const [registerName, setRegisterName] = useState("")
    const [registerUsername, setRegisterUsername] = useState("")
    const [registerBirthdate, setRegisterBirthdate] = useState("")
    const [registerEmail, setRegisterEmail] = useState("")
    const [registerPhoneNumber, setRegisterPhoneNumber] = useState("")
    const [registerPassword, setRegisterPassword] = useState("")
    const [registerRepeatPassword, setRegisterRepeatPassword] = useState("")

    const onSubmit = (e) => {
        e.preventDefault();

        const attributeList = [];
        attributeList.push({ "Name": 'name', "Value": registerName });
        attributeList.push({ "Name": 'nickname', "Value": registerUsername });
        attributeList.push({ "Name": 'birthdate', "Value": registerBirthdate });
        attributeList.push({ "Name": 'phone_number', "Value": "+57" + registerPhoneNumber });

        UserPool.signUp(registerEmail, registerPassword, attributeList, null, (err, data) => {
            if (err) {
                console.error(err)
            }
        })
    }

    const toggleShowPassword = (idInput, idEye) => {
        const passwordInput = document.getElementById(idInput);
        const togglePassword = document.getElementById(idEye);

        if (passwordInput.type === "password") {
            passwordInput.type = "text";
            togglePassword.classList.remove("fa-eye-slash");
            togglePassword.classList.add("fa-eye");
        } else {
            passwordInput.type = "password";
            togglePassword.classList.remove("fa-eye");
            togglePassword.classList.add("fa-eye-slash");
        }
    }

    return (
        <div className="card">
            <h2 className="text-center mb-4">Información nuevo usuario</h2>
            <form className="form-card" onSubmit={onSubmit}>
                <div className="row justify-content-between text-left">
                    <div className="form-group col-md-4 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="registerName">Nombre<span className="text-danger"> *</span></label>
                        <input type="text" id="registerName" name="registerName" placeholder="Nombre completo"
                            className="form-control" value={registerName} onChange={(e) => setRegisterName(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="registerUsername">Usuario<span className="text-danger"> *</span></label>
                        <input type="text" id="registerUsername" name="registerUsername" placeholder="Usuario personalizado"
                            className="form-control" value={registerUsername} onChange={(e) => setRegisterUsername(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="registerBirthdate">Fecha de nacimiento<span className="text-danger"> *</span></label>
                        <input type="date" id="registerBirthdate" name="registerBirthdate" placeholder=""
                            className="form-control" value={registerBirthdate} onChange={(e) => setRegisterBirthdate(e.target.value)} />
                    </div>
                </div>
                <div className="row justify-content-between text-left">
                    <div className="form-group col-md-6 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="registerPhoneNumber">Teléfono<span className="text-danger"> *</span></label>
                        <input type="text" id="registerPhoneNumber" name="registerPhoneNumber" placeholder="3121234567"
                            className="form-control" value={registerPhoneNumber} onChange={(e) => setRegisterPhoneNumber(e.target.value)} />
                    </div>
                    <div className="form-group col-md-6 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="registerEmail">Correo<span className="text-danger"> *</span></label>
                        <input type="email" id="registerEmail" name="registerEmail" placeholder="micorreo@mail.com"
                            className="form-control" value={registerEmail} onChange={(e) => setRegisterEmail(e.target.value)} />
                    </div>
                </div>
                <div className="row justify-content-between text-left">
                    <div className="form-group col-md-6 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="registerPassword">Contraseña<span className="text-danger"> *</span></label>
                        <div className="input-group" id="show_hide_password">
                            <input type="password" id="registerPassword" name="registerPassword" placeholder=""
                                className="form-control" value={registerPassword} onChange={(e) => setRegisterPassword(e.target.value)} />
                            <div id="contTogglePassword" className="input-group-addon">
                                <i id="togglePassword" className="fa fa-eye-slash" aria-hidden="true"
                                    onClick={() => toggleShowPassword("registerPassword", "togglePassword")}></i>
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-md-6 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="registerRepeatPassword">Repetir Contraseña<span className="text-danger"> *</span></label>
                        <div className="input-group" id="show_hide_password">
                            <input type="password" id="registerRepeatPassword" name="registerRepeatPassword" placeholder=""
                                className="form-control" value={registerRepeatPassword} onChange={(e) => setRegisterRepeatPassword(e.target.value)} />
                            <div id="contTogglePassword" className="input-group-addon">
                                <i id="toggleRePassword" className="fa fa-eye-slash" aria-hidden="true"
                                    onClick={() => toggleShowPassword("registerRepeatPassword", "toggleRePassword")}></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="text-center mt-4">
                        <button type="submit" className="btn btn-success">Crear Usuario</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Signup