import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { PuereMap, Sidebar } from "../components" // componente mapa
import { addGeoJson, measure, addButton } from "../services/geographic"; // Servicios geograficos
import geojson from '../assets/data/geojson.json';
import "./styles.css";
import { Petition } from "../helpers/petition/Petition";
import { Checkbox } from "../theme/styleds";
import * as turf from '@turf/turf';
import LoadingModal from '../components/loading/LoadingModal';
import 'esri-leaflet';

const Geovisor = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [map, setMap] = useState();
    const [L, setL] = useState();
    const [dataGeoJson, setDataGeoJson] = useState({});
    const [dataGeoJsonAlerts, setDataGeoJsonAlerts] = useState({});
    const [dataFarmsProject, setDataFarmsProject] = useState([]);
    const [dataFarm, setDataFarm] = useState({ properties: {} });
    const [dataClassifications, setDataClassifications] = useState([{}]);
    const [dataReports, setDataReports] = useState([]);
    const [dataAlerts, setDataAlerts] = useState([]);
    const [projectID, setProjectID] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [alertTot, setAlertTot] = useState(null);
    const [alertTotDesign, setAlertTotDesign] = useState(null);
    const [dataProject, setDataProject] = useState({ projects: [] });
    const [selectedFarmId, setSelectedFarmId] = useState(null);
    const [loadingModal, setLoadingModal] = useState(false);
    const [flow, setFlow] = useState(1);
    const [alertLayer, setAlertLayer] = useState(null);
    const [updatingAlertLayer, setUpdatingAlertLayer] = useState(false);
    const [geometryFarm, setGeometryFarm] = useState(null);
    const [dataDeterminantes, setDataDeterminantes] = useState(null);
    const alertLayerRef = useRef(alertLayer);
    const [opcionActiva, setOpcionActiva] = useState('reportes');
    const mapRef = useRef();

    useEffect(() => {
        // Actualizar la referencia cuando alertLayer cambie
        alertLayerRef.current = alertLayer;
    }, [alertLayer]);


    useEffect(() => {
        if (state === null) {
            navigate("/");
        }
    }, []);

    useEffect(() => {
        setLoadingModal(true);
        const fetchData = async () => {
            let resultClient = '';
            try {
                resultClient = await Petition(`clients/${state.data.email}`, {
                    method: "GET",
                    type: "no-geographic",
                    headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                });
                setDataProject(resultClient);
            } catch (error) {
                console.error("Error fetching GeoJSON data:", error);
            }
            if (resultClient) {
                try {
                    let arrayGeoJson = [];
                    for (const resPro of resultClient.projects) {
                        const result = await Petition(`farms?project_id=${resPro.project_id}`, {
                            method: "GET",
                            type: "geographic",
                            headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                        });
                        arrayGeoJson = arrayGeoJson.concat(result);
                    }
                    setDataGeoJson(arrayGeoJson);
                    setDataFarmsProject(arrayGeoJson)

                    // Recorre el arreglo y extrae solo la propiedad 'properties'
                    const propertiesArray = arrayGeoJson.map(objeto => objeto.properties);

                    localStorage.setItem('dataFarmsProject', JSON.stringify(propertiesArray));

                    //Consulta total de fincas
                    const getTotalFarms = await Petition(`statistics/farms`, {
                        method: "GET",
                        type: "no-geographic",
                        headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                    });
                    
                    localStorage.setItem('totalFarms', JSON.stringify(getTotalFarms));

                    //Consulta total de alertas
                    const getTotalAlerts = await Petition(`statistics/alerts`, {
                        method: "GET",
                        type: "no-geographic",
                        headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                    });
                    localStorage.setItem('totalAlerts', JSON.stringify(getTotalAlerts));

                    //Consulta total de alertas
                    const getTotalReports = await Petition(`statistics/reports`, {
                        method: "GET",
                        type: "no-geographic",
                        headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                    });
                    localStorage.setItem('totalReports', JSON.stringify(getTotalReports));

                    //Consulta total de areas de cam2020io
                    const getTotalChange = await Petition(`statistics/change/2020`, {
                        method: "GET",
                        type: "no-geographic",
                        headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                    });
                    
                    localStorage.setItem('totalChange', JSON.stringify(getTotalChange));

                    //Consulta total de areas de cambio
                    const getTotalClassification = await Petition(`statistics/classification/2020`, {
                        method: "GET",
                        type: "no-geographic",
                        headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                    });
                    
                    localStorage.setItem('totalClassification', JSON.stringify(getTotalClassification));

                    //Consulta información alertas
                    const getInfoAlerts = await Petition(`alerts`, {
                        method: "GET",
                        type: "no-geographic",
                        headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                    });
                    localStorage.setItem('infoAlerts', JSON.stringify(getInfoAlerts));

                    //Consulta información reportes
                    const getInfoReports = await Petition(`reports`, {
                        method: "GET",
                        type: "no-geographic",
                        headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
                    });
                    localStorage.setItem('infoReports', JSON.stringify(getInfoReports));
                } catch (error) {
                    console.error("Error fetching GeoJSON data:", error);
                }
                setLoadingModal(false);
            }
            //toggleSidebar()
            toggleSidebarProject()
        };

        fetchData();
    }, [state]);

    useEffect(() => {
        if (map && Object.keys(dataGeoJson).length > 0) {
            setLoadingModal(true);
            addButton(
                map, L, "ruler-vertical", "topleft",
                "Medir Longitud", "Medir Longitud", () => {
                    measure(map, L, "distance");
                }
            );

            addButton(
                map, L, "ruler-combined", "topleft",
                "Medir Área", "Medir Área", () => {
                    measure(map, L, "area", "#FF00FF");
                }
            );

            const layerGeoJSON = addGeoJson(map, L, dataGeoJson, null, onEachFeature);
            layerGeoJSON.addTo(map);

            // variables para acumular las coordenadas
            let totalLat = 0;
            let totalLon = 0;

            // Iterar sobre todos los polígonos en el GeoJSON
            dataGeoJson.forEach(polygon => {
                const centroid = turf.centroid(polygon);
                const lon = centroid.geometry.coordinates[0];
                const lat = centroid.geometry.coordinates[1];

                // Acumulamos las coordenadas
                totalLon += lon;
                totalLat += lat;
            });

            // Calcular el centroide promedio
            const avgLon = totalLon / dataGeoJson.length;
            const avgLat = totalLat / dataGeoJson.length;

            // Centrar el mapa en el centroide promedio
            map.setView([avgLat, avgLon], 12);
            setLoadingModal(false);
        }
    }, [map, dataGeoJson]);

    function handleExtendClick(farmId, farmGeometry) {

        const centroid = turf.centroid(farmGeometry);
        const lon = centroid.geometry.coordinates[0];
        const lat = centroid.geometry.coordinates[1];

        map.setView([lat, lon], 18);

        setSelectedFarmId(farmId);
    }

    async function handleFarmClick(farmId) {
        setLoadingModal(true);
        // Remover la capa existente del GeoJSON de alertas antes de agregar la nueva
        if (alertLayer) {
            map.removeLayer(alertLayer);
            setAlertLayer(null); // Establecer alertLayer en null
        }
        await consultFarm(farmId)
        setLoadingModal(false);
        toggleSidebarDetailPredio()
    }

    function onEachAlertFeature(f, l) {
        l.on({ click: getPropertiesFeature });
        if (f.properties && f.properties.category) {
            const category = f.properties.category; // Obtener la categoría del properties de alertas

            let color;
            switch (category) {
                case 'high':
                    color = 'red';
                    break;
                case 'medium':
                    color = 'yellow';
                    break;
                case 'low':
                    color = 'green';
                    break;
                default:
                    color = 'blue'; // Puedes establecer un color predeterminado para otras categorías o manejarlo según tus necesidades.
                    break;
            }

            l.setStyle({
                fillColor: color,
                color: 'white',
                weight: 2,
                opacity: 1,
                fillOpacity: 0.7
            });

            l.bindPopup(`
            <div class="row cont-popup-predio">
            <div class="col-7">
                <h4 class="title-popup-predio"> ${f.properties.datetime}</h4>
                <div class="cont-item-popup">
                    <span class="item-popup"> <b>Causa:</b>  ${f.properties.cause}</span>
                </div>
                <div class="cont-item-popup">
                    <span class="item-popup"> <b>Descripción:</b>  ${f.properties.description}</span>
                </div>
                <div class="cont-item-popup">
                    <span class="item-popup"> <b>Sugerencias:</b>  ${f.properties.suggestions}</span>
                </div>
            </div>
            <div class="col-5 cont-left-popup">
                <img src=${require("../assets/images/icons/icon_popup.png")} alt="Icon PopUp" class="icon-popup"></img>                        
                <button id="btnSeePredio" class="btn-popup">Ver predio</button>
            </div>
        </div>
            `);
        }
    }

    function onEachFeature(f, l) {
        l.on({ click: getPropertiesFeature });
        if (f.properties && f.properties.departamento) {
            l.bindPopup(`
                <div class="row cont-popup-predio">
                    <div class="col-7">
                        <h4 class="title-popup-predio"> ${f.properties.name}</h4>
                        <div class="cont-item-popup">
                            <span class="item-popup"><i class="fa-solid fa-location-dot"></i> ${f.properties.departamento}</span>
                        </div>
                        <div class="cont-item-popup">
                            <span class="item-popup"><i class="fas fa-seedling"></i> cultivo de ${f.properties.crop}</span>
                        </div>
                        <div class="cont-item-popup">
                            <span class="item-popup"><i class="fa-solid fa-expand"></i> ${parseFloat(f.properties.area).toFixed(2)} ha</span>
                        </div>
                    </div>
                    <div class="col-5 cont-left-popup">
                        <img src=${require("../assets/images/icons/icon_popup.png")} alt="Icon PopUp" class="icon-popup"></img>                        
                        <button id="btnSeePredio" class="btn-popup">Ver predio</button>
                    </div>
                </div>
            `);
        }
    }

    function getPropertiesFeature(e) {
        setTimeout(async () => {
            const btnSeePredio = document.getElementById('btnSeePredio');
            btnSeePredio.onclick = () => goSeePredio(e.sourceTarget.feature.properties);
        }, 500);
    }

    const goSeePredio = async (joinFarm) => {
        setLoadingModal(true);
        setProjectName(null);
        // Remover la capa existente del GeoJSON de alertas antes de agregar la nueva
        if (alertLayerRef.current) {
            map.removeLayer(alertLayerRef.current);
            setAlertLayer(null); // Establecer alertLayer en null
        }
        await consultFarm(joinFarm.farm_id)
        setLoadingModal(false);
        toggleSidebar()
        toggleSidebarDetailPredio()
    }

    const toggleSidebar = () => {
        const contSidePanelInfo = document.getElementById('contSidePanelInfo')
        const sidePanelInfo = document.getElementById('sidePanelInfo')

        if (contSidePanelInfo) {
            switch (contSidePanelInfo.style.width) {
                case "600px":
                    contSidePanelInfo.style.width = "0px";
                    sidePanelInfo.style.display = "none";
                    break;
                default:
                    contSidePanelInfo.style.width = "600px";
                    sidePanelInfo.style.display = "block";
                    break;
            }
        }
    }

    const toggleFilterPredios = () => {
        const contPanelFilterPredios = document.getElementById('contPanelFilterPredios')
        if (contPanelFilterPredios) {
            switch (contPanelFilterPredios.style.display) {
                case "block":
                    contPanelFilterPredios.style.display = "none";
                    break;
                default:
                    contPanelFilterPredios.style.display = "block";
                    break;
            }
        }
    }

    const toggleSidebarProject = () => {
        /*const contPanelListFolders = document.getElementById('contPanelListFolders')
        contPanelListFolders.style.display = "block";*/

        const contPanelListDetailPredio = document.getElementById('contPanelListDetailPredio')
        contPanelListDetailPredio.style.display = "none";

        const contPanelListDetailPredioAlerts = document.getElementById('contPanelListDetailPredioAlerts')
        contPanelListDetailPredioAlerts.style.display = "none";

        const contPanelListDetailPredioDeterminantes = document.getElementById('contPanelListDetailPredioDeterminantes')
        contPanelListDetailPredioDeterminantes.style.display = "none";
    }


    const toggleSidebarDetailPredio = () => {
        setOpcionActiva('predios');
        const contPanelListFolders = document.getElementById('contPanelListFolders')
        contPanelListFolders.style.display = "none";
    
        const contPanelListPredios = document.getElementById('contPanelListPredios')
        contPanelListPredios.style.display = "none";
    
        const contPanelListDetailPredio = document.getElementById('contPanelListDetailPredio')
        contPanelListDetailPredio.style.display = "block";
    
        const contPanelListDetailPredioAlerts = document.getElementById('contPanelListDetailPredioAlerts')
        contPanelListDetailPredioAlerts.style.display = "none";
    
        const contPanelListDetailPredioDeterminantes = document.getElementById('contPanelListDetailPredioDeterminantes')
        contPanelListDetailPredioDeterminantes.style.display = "none";
    
        // Cambiar clase del botón seleccionado
        document.getElementById('btnDetailPredio').classList.add('btn-active');
        document.getElementById('btnAlerts').classList.remove('btn-active');
        document.getElementById('btnDetailDeterminantes').classList.remove('btn-active');
    }
    
    const toggleSidebarDetailDeterminantes = () => {
        setOpcionActiva('determinantes');
        const contPanelListFolders = document.getElementById('contPanelListFolders')
        contPanelListFolders.style.display = "none";
    
        const contPanelListPredios = document.getElementById('contPanelListPredios')
        contPanelListPredios.style.display = "none";
    
        const contPanelListDetailPredioDeterminantes = document.getElementById('contPanelListDetailPredioDeterminantes')
        contPanelListDetailPredioDeterminantes.style.display = "block";
    
        const contPanelListDetailPredio = document.getElementById('contPanelListDetailPredio')
        contPanelListDetailPredio.style.display = "none";
    
        const contPanelListDetailPredioAlerts = document.getElementById('contPanelListDetailPredioAlerts')
        contPanelListDetailPredioAlerts.style.display = "none";
    
        // Cambiar clase del botón seleccionado
        document.getElementById('btnDetailPredio').classList.remove('btn-active');
        document.getElementById('btnAlerts').classList.remove('btn-active');
        document.getElementById('btnDetailDeterminantes').classList.add('btn-active');
    }
    
    const toggleSidebarAlerts = () => {
        setOpcionActiva('alertas');
        const contPanelListFolders = document.getElementById('contPanelListFolders')
        contPanelListFolders.style.display = "none";
    
        const contPanelListPredios = document.getElementById('contPanelListPredios')
        contPanelListPredios.style.display = "none";
    
        const contPanelListDetailPredio = document.getElementById('contPanelListDetailPredio')
        contPanelListDetailPredio.style.display = "none";
    
        const contPanelListDetailPredioAlerts = document.getElementById('contPanelListDetailPredioAlerts')
        contPanelListDetailPredioAlerts.style.display = "block";
    
        const contPanelListDetailPredioDeterminantes = document.getElementById('contPanelListDetailPredioDeterminantes')
        contPanelListDetailPredioDeterminantes.style.display = "none";
    
        // Cambiar clase del botón seleccionado
        document.getElementById('btnDetailPredio').classList.remove('btn-active');
        document.getElementById('btnAlerts').classList.add('btn-active');
        document.getElementById('btnDetailDeterminantes').classList.remove('btn-active');
    }

    const handleProjectClick = async (dataProject) => {
        setProjectID(dataProject.project_id)
        setProjectName(dataProject.name)
        const contPanelListFolders = document.getElementById('contPanelListFolders')
        contPanelListFolders.style.display = "none";

        const contPanelListPredios = document.getElementById('contPanelListPredios')
        contPanelListPredios.style.display = "block";

        await consultFarmProject(dataProject.project_id);
    };

    const handleProjectBlock = () => {
        setProjectID(null)
        setProjectName(null)
        const contPanelListFolders = document.getElementById('contPanelListFolders')
        contPanelListFolders.style.display = "block";

        const contPanelListPredios = document.getElementById('contPanelListPredios')
        contPanelListPredios.style.display = "none";

        const contPanelListDetailPredio = document.getElementById('contPanelListDetailPredio')
        contPanelListDetailPredio.style.display = "none";

        const contPanelListDetailPredioAlerts = document.getElementById('contPanelListDetailPredioAlerts')
        contPanelListDetailPredioAlerts.style.display = "none";

        const contPanelListDetailPredioDeterminantes = document.getElementById('contPanelListDetailPredioDeterminantes')
        contPanelListDetailPredioDeterminantes.style.display = "none";
    };

    const handleFarmsProjectBlock = () => {
        const contPanelListFolders = document.getElementById('contPanelListFolders')
        contPanelListFolders.style.display = "none";

        const contPanelListPredios = document.getElementById('contPanelListPredios')
        contPanelListPredios.style.display = "block";

        const contPanelListDetailPredio = document.getElementById('contPanelListDetailPredio')
        contPanelListDetailPredio.style.display = "none";

        const contPanelListDetailPredioAlerts = document.getElementById('contPanelListDetailPredioAlerts')
        contPanelListDetailPredioAlerts.style.display = "none";

        const contPanelListDetailPredioDeterminantes = document.getElementById('contPanelListDetailPredioDeterminantes')
        contPanelListDetailPredioDeterminantes.style.display = "none";
    };



    const consultFarmProject = async (idPro) => {
        try {
            const result = await Petition(`farms?project_id=${idPro}`, {
                method: "GET",
                type: "geographic",
                headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
            });
            setDataFarmsProject(result)
        } catch (error) {
            console.error("Error fetching GeoJSON data:", error);
        }
    };

    const consultFarm = async (idFarm) => {
        const esri = await import('esri-leaflet');
        try {
            const result = await Petition(`farms/${idFarm}`, {
                method: "GET",
                type: "no-geographic",
                headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
            });

            localStorage.setItem('geometryFarmLocal', JSON.stringify(result.geometry));
            setGeometryFarm(result.geometry);
            const centroidFarm = turf.centroid(result.geometry);
            const lonX = centroidFarm.geometry.coordinates[0];
            const latY = centroidFarm.geometry.coordinates[1];
            result.x = parseFloat(lonX).toFixed(4);
            result.y = parseFloat(latY).toFixed(4);
            setDataFarm(result)
        } catch (error) {
            console.error("Error fetching GeoJSON data:", error);
        }

        try {
            const resultClassifications = await Petition(`farms/${idFarm}/classifications`, {
                method: "GET",
                type: "no-geographic",
                headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
            });
            if (resultClassifications.length > 0) {
                const ultimoRegistro = resultClassifications[resultClassifications.length - 1];
                setDataClassifications(ultimoRegistro)
            }
        } catch (error) {
            console.error("Error fetching GeoJSON data:", error);
        }

        try {
            const resultReports = await Petition(`farms/${idFarm}/reports`, {
                method: "GET",
                type: "no-geographic",
                headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
            });
            setDataReports(resultReports)
        } catch (error) {
            console.error("Error fetching GeoJSON data:", error);
        }

        try {
            let resultAlerts = await Petition(`farms/${idFarm}/alerts`, {
                method: "GET",
                type: "no-geographic",
                headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
            });
            let alertDesc = [];
            for (const editAlerts of resultAlerts) {
                switch (editAlerts.category) {
                    case 'high':
                        resultAlerts.category_description = 'Riesgo alto';
                        alertDesc.push('high');
                        break;
                    case 'medium':
                        resultAlerts.category_description = 'Riesgo medio';
                        alertDesc.push('medium');
                        break;
                    case 'low':
                        resultAlerts.category_description = 'Riesgo bajo';
                        alertDesc.push('low');
                        break;

                    default:
                        break;
                }
            }
            if (alertDesc.length > 0) {
                if (alertDesc.includes('high')) {
                    setAlertTotDesign('high')
                    setAlertTot('Alta deforestación')
                } else if (alertDesc.includes('medium')) {
                    setAlertTotDesign('medium')
                    setAlertTot('Media deforestación')
                } else if (alertDesc.includes('low')) {
                    setAlertTotDesign('free')
                    setAlertTot('Baja deforestación')
                }
            } else {
                setAlertTotDesign('free')
                setAlertTot('Libre deforestación')
            }

            setDataAlerts(resultAlerts)
        } catch (error) {
            console.error("Error fetching GeoJSON data:", error);
        }

        try {
            const resultAlertGeo = await Petition(`farms/${idFarm}/geoalerts`, {
                method: "GET",
                type: "geographic",
                headers: { "x-api-key": "dlAmmmW7LBCyF3KKkaTPh3sPzk781j1TY9vCoK70" }
            });

            // Establecer el estado de actualización en verdadero
            setUpdatingAlertLayer(true);

            setDataGeoJsonAlerts(resultAlertGeo);

            if (map && Object.keys(resultAlertGeo).length > 0) {
                // Almacenar la nueva capa en el estado
                const newAlertLayer = addGeoJson(map, L, resultAlertGeo, null, onEachAlertFeature);
                setAlertLayer(newAlertLayer);
            }

            // Establecer el estado de actualización en falso después de la actualización
            setUpdatingAlertLayer(false);
        } catch (error) {
            console.error("Error fetching GeoJSON data:", error);
        }


        const layers = [
            {
                name: 'Resguardos Indígenas',
                url: 'https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Resguardos_Ind%C3%ADgenas/FeatureServer/0',
                fillColor: 'green' // Color para Resguardos Indígenas
            },            
            {
                name: 'Consejos Comunitarios',
                url: 'https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Consejos_Comunitarios/FeatureServer/0',
                fillColor: 'red' // Color para Consejos Comunitarios
            },
            {
                name: 'Áreas Protegidas',
                url: 'https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/%C3%81reas_Protegidas/FeatureServer/0',
                fillColor: 'yellow' // Color para Áreas Protegidas
            },
        ];
        
        const overlayLayers = {};
        const geometryFarmA = JSON.parse(localStorage.getItem('geometryFarmLocal'));
        
        // Objeto para almacenar los datos de los features que cruzan la geometría de la finca, clasificados por tipo de área protegida
        const crossedFeatures = {
            'Resguardos Indígenas': [],
            'Consejos Comunitarios': [],
            'Áreas Protegidas': [],
        };
        layers.forEach(layer => {
            // Obtener la capa del servicio
            const featureLayer = esri.featureLayer({
                url: layer.url,
                token: localStorage.getItem('tokenArcgis'),
                simplifyFactor: 0.9,
                style: function (feature) {
                    // Coloca un punto de interrupción aquí para examinar el estado en tiempo de ejecución
                    debugger;
                    // Verificar si el feature intersecta con la geometría de la finca
                    const isIntersecting = turf.intersect(feature.geometry, geometryFarmA);
                    // Establecer el color de relleno según si intersecta o no
                    const fillColor = isIntersecting ? 'blue' : layer.fillColor;       
                    
        
                    return { fillColor: fillColor, color: 'black', weight: 1, opacity: 1, fillOpacity: 0.5 };
                }
            });
            
            // Realizar la intersección con la geometría de la finca
            featureLayer.query().intersects(geometryFarmA).run((error, featureCollection) => {
                if (error) {
                    console.error('Error querying features:', error);
                    return;
                }
                
                // Agregar la capa resultante al mapa
                L.geoJSON(featureCollection, {
                    style: { fillColor: 'blue', color: 'black', weight: 1, opacity: 1, fillOpacity: 0.5 },
                    onEachFeature: function(feature, layer) {
                        // Agregar el nombre de la capa al atributo 'layerName' del feature
                        feature.properties.layerName = layer.name;
                    }
                }).addTo(map);
                
                // Almacenar la capa en overlayLayers si es necesario
                overlayLayers[layer.name] = featureLayer;

                // Agregar los datos de los features que intersectan al objeto crossedFeatures
                featureCollection.features.forEach(feature => {
                    crossedFeatures[layer.name].push({
                        type: layer.name,
                        properties: feature.properties,
                    });
                    setDataDeterminantes(crossedFeatures)
                });
            });
        });
        
    };


    const getAlertClass = () => {
        const lastAlert = dataAlerts[dataAlerts.length - 1];

        if (lastAlert) {
            switch (lastAlert.category) {
                case 'high':
                    return 'high';
                case 'medium':
                    return 'medium';
                case 'low':
                    return 'low';
                default:
                    return '';
            }
        }

        return '';
    };

    const semaphore = (alertCategories) => {
        if (alertCategories.includes('high')) {
            return 'red'; // Riesgo alto
        } else if (alertCategories.includes('medium')) {
            return 'yellow'; // Riesgo medio
        } else if (alertCategories.includes('low')) {
            return 'green'; // Riesgo bajo
        } else {
            return 'minor'; // Sin riesgo
        }
    };

    const handleDownloadClick = async (url, year) => {
        // Crear un enlace temporal
        /*const link = document.createElement('a');
        link.href = url;
        link.download = `Reporte_${year}.pdf`;
        document.body.appendChild(link);
    
        // Simular el clic en el enlace para iniciar la descarga
        link.click();
    
        // Eliminar el enlace temporal después de la descarga
        document.body.removeChild(link);*/
        window.open(url, '_blank');
    };

    return (
        <div className="d-flex flex-column">
            {loadingModal && <LoadingModal />}
            <Sidebar navTitle={"ESTUDIOS"} />
            <div id="contMap">
                <PuereMap setMap={setMap} setL={setL} map={map} ref={mapRef} />

                <div id="contSidePanelInfo">
                    {/******************** DIV FILTRO PREDIOS *******************************/}
                    <div id="contPanelFilterPredios">
                        <div className="row">
                            <div className="col-10 pb-3 ps-4 pe-2 d-flex">
                                <h3>Filtrar por</h3>
                            </div>
                            <div className="col-2 pb-3 ps-4 pe-2 d-flex justify-content-end">
                                <div className="d-flex align-items-center icon-list-predios">
                                    <a type="button" onClick={toggleFilterPredios}>
                                        <i className="bx bx-x nav_icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 py-3 d-flex justify-content-center">
                                <div className="item-box-filter">
                                    <div className="d-flex align-items-center icon-list-predios">
                                        <i className="bx bx-folder nav_icon"></i>
                                    </div>
                                    <span className="text-end w-100">Carpetas</span>
                                </div>
                            </div>
                            <div className="col-6 py-3 d-flex justify-content-center">
                                <div className="item-box-filter">
                                    <div className="d-flex align-items-center icon-list-predios">
                                        <i className="bx bx-list-ul nav_icon"></i>
                                    </div>
                                    <span className="text-end w-100">Predios</span>
                                </div>
                            </div>
                        </div>
                        <h5 className="px-2 py-2">Tipo de cultivo</h5>
                        <div className="row">
                            <div className="col-3 py-3 pe-1 d-flex justify-content-center">
                                <div className="item-box-filter">
                                    <div className="d-flex align-items-center icon-list-predios">
                                        <i className="bx bx-spa nav_icon"></i>
                                    </div>
                                    <span className="text-end w-100">Cacao</span>
                                </div>
                            </div>
                            <div className="col-3 py-3 pe-1 d-flex justify-content-center">
                                <div className="item-box-filter">
                                    <div className="d-flex align-items-center icon-list-predios">
                                        <i className="bx bx-lemon nav_icon"></i>
                                    </div>
                                    <span className="text-end w-100">Café</span>
                                </div>
                            </div>
                            <div className="col-3 py-3 pe-1 d-flex justify-content-center">
                                <div className="item-box-filter">
                                    <div className="d-flex align-items-center icon-list-predios">
                                        <i className="bx bx-mask nav_icon"></i>
                                    </div>
                                    <span className="text-end w-100">Vacuno</span>
                                </div>
                            </div>
                            <div className="col-3 py-3 pe-1 d-flex justify-content-center">
                                <div className="item-box-filter">
                                    <div className="d-flex align-items-center icon-list-predios">
                                        <i className="bx bx-leaf nav_icon"></i>
                                    </div>
                                    <span className="text-end w-100">Madera</span>
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <h5 className="px-2 pb-2">Ubicación</h5>
                            <p className="px-2 pb-2">Departamento / Región</p>
                            <div className="mb-3">
                                <div className="pb-3 px-2 d-inline-block justify-content-center">
                                    <div className="item-box-filter">
                                        <span>Antioquia</span>
                                    </div>
                                </div>
                                <div className="pb-3 px-2 d-inline-block justify-content-center">
                                    <div className="item-box-filter">
                                        <span>Cundinamarca</span>
                                    </div>
                                </div>
                                <div className="pb-3 px-2 d-inline-block justify-content-center">
                                    <div className="item-box-filter">
                                        <span>Boyacá</span>
                                    </div>
                                </div>
                            </div>
                            <p className="px-2 pb-2">Municipio</p>
                            <div className="row">
                                <div className="col-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Paipa
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Duitama
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Paipa
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Duitama
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Duitama
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Sogamoso
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Sasaima
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Paipa
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Duitama
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Duitama
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Chiqinquira
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Duitama
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Paipa
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Duitama
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Duitama
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <h5 className="px-2 pb-2">Creación</h5>
                            <div className="my-3">
                                <div className="pb-3 px-2 d-inline-block justify-content-center">
                                    <div className="item-box-filter">
                                        <span>2020</span>
                                    </div>
                                </div>
                                <div className="pb-3 px-2 d-inline-block justify-content-center">
                                    <div className="item-box-filter">
                                        <span>2021</span>
                                    </div>
                                </div>
                                <div className="pb-3 px-2 d-inline-block justify-content-center">
                                    <div className="item-box-filter">
                                        <span>2022</span>
                                    </div>
                                </div>
                                <div className="pb-3 px-2 d-inline-block justify-content-center">
                                    <div className="item-box-filter">
                                        <span>2024</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <div className="row">
                                <div className="col-6 py-3 d-flex">
                                    <div className="btn-filter cursor-action">
                                        Limpiar filtro
                                    </div>
                                </div>
                                <div className="col-6 py-3 d-flex">
                                    <div className="btn-filter cursor-action">
                                        Seleccionar filtros
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="sidePanelInfo">
                        {/******************** DIV HOME *******************************/}
                        <div id="contPanePredio" className="container">
                            <div className="row">
                                <p className="px-1">
                                    <span id="dirNameFolder">Predio / </span>
                                    <span id="dirNameSubFolder">Norte de Santander / </span>
                                    <span id="dirNamePredio">Finca San José</span>
                                </p>
                                <h3 className="px-1">Finca San José</h3>
                            </div>
                            <div className="row mt-4">
                                <div className="col-4 btn-card px-1">
                                    <div className="btn-card-inner btn-active">Reportes</div>
                                </div>
                                <div className="col-4 btn-card px-1">
                                    <div className="btn-card-inner btn-not-active">Alertas</div>
                                </div>
                                <div className="col-4 btn-card px-1">
                                    <div className="btn-card-inner btn-not-active">Evidencias</div>
                                </div>
                            </div>
                        </div>

                        {/******************** DIV PROYECTOS *******************************/}
                        <div id="contPanelListFolders">
                            <div className="container">
                                <div className="row my-3">
                                    <div className="col-6 p-1">
                                        <h3>Proyectos</h3>
                                    </div>
                                    <div className="col-6 text-end p-1">
                                        <div className="btn-card-inner btn-not-active d-inline-block">+ Nuevo predio</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a type="button" onClick={toggleFilterPredios}>
                                    <i className="bx bx-filter nav_icon"></i>
                                </a>
                            </div>
                            <div className="container cont-card-folder">
                                {dataProject.projects.map((project, key) => (
                                    <div
                                        className="row card-folder"
                                        key={project.project_id}
                                        onClick={() => handleProjectClick(project)}
                                    >
                                        <div className="col-9 py-3 ps-4 pe-2">
                                            <div className="d-flex mb-3">
                                                <div className="thumbnail-predio">
                                                    <img src={require("../assets/images/thumbnails/thumbnail-example.png")} alt="Thumbnail"></img>
                                                </div>
                                                <div className="thumbnail-predio">
                                                    <img src={require("../assets/images/thumbnails/thumbnail-example.png")} alt="Thumbnail"></img>
                                                </div>
                                                <div className="thumbnail-predio box-count-predios">{project.n_farms}</div>
                                                <div className="text-count-predios ms-2">Predios</div>
                                            </div>
                                            <strong>{project.name}</strong>
                                        </div>
                                        <div className="col-3 py-3 ps-2 pe-4">
                                            <div className="mb-3 dates-box">Creación: {project.creation_date}</div>
                                            <div className="mb-3 dates-box">Modificación: {project.last_modified_date}</div>
                                            {/*<div className="d-flex">
                                                <div className={`item-circle-${semaphore(project.alert_categories)}`}></div>
                                                <div>{project.alert_categories.length}</div>
                                </div>*/}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/******************** DIV PREDIOS *******************************/}
                        <div id="contPanelListPredios">
                            <div className="row">
                                <p className="px-1">
                                    <span id="dirNameFolder" onClick={() => handleProjectBlock()}>proyectos / </span>
                                    <span id="dirNamePredio">{projectName} / </span>
                                </p>
                            </div>
                            <div className="container">
                                <div className="row my-3">
                                    <div className="col-6 p-1">
                                        <h3>Predios</h3>
                                    </div>
                                    <div className="col-6 text-end p-1">
                                        <div className="btn-card-inner btn-not-active d-inline-block">+ Nuevo predio</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7 py-3 ps-4 pe-2 d-flex">
                                    <div className="d-flex align-items-center icon-list-predios">
                                        <a type="button" onClick={toggleFilterPredios}>
                                            <i className="bx bx-filter nav_icon"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-5 py-3 ps-2 pe-4 d-flex align-items-center justify-content-end">
                                    <div className="d-flex icon-list-predios">
                                        <select className="form-select" aria-label="Default select example">
                                            <option value="">Ordenar por</option>
                                            <option value="1">Nombre</option>
                                            <option value="2">Predios</option>
                                            <option value="3">Fecha de creación</option>
                                            <option value="3">Fecha de modificación</option>
                                        </select>
                                    </div>
                                    <div className="d-flex icon-list-predios">
                                        <i className="bx bx-list-ul nav_icon"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="container cont-card-predios">
                                {dataFarmsProject.map((farm, key) => (
                                    <div key={key} className="row card-folder">
                                        <div className="col-8 py-3 ps-4 pe-2">
                                            <div className="d-flex align-items-center">
                                                <input className="me-2" type="checkbox"></input>
                                                <div className="thumbnail-predio">
                                                    <img src={require("../assets/images/thumbnails/thumbnail-example.png")}></img>
                                                </div>
                                                <div className="text-count-predios ms-2">
                                                    <strong>{farm.properties.name}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 py-3 ps-2 pe-4 d-flex align-items-center justify-content-end">
                                            <div className="d-inline-block icon-list-predios">
                                                <i className="bx bx-download" onClick={() => handleFarmClick(farm.properties.farm_id)}></i>
                                            </div>
                                            <div className="d-inline-block icon-list-predios" onClick={() => handleExtendClick(farm.properties.farm_id, farm.geometry)}>
                                                <i className="bx bx-file-find"></i>
                                            </div>
                                            <div className="item-circle-minor d-inline-block"></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/******************** DIV DETALLE PREDIO *******************************/}
                        {dataFarm && (
                            <>
                                <div id="contPanelListDetailPredio">
                                    <div className="cont-header-detail-predio">
                                        <div className="row">
                                            <p className="px-1">
                                                <span id="dirNameFolder" onClick={() => handleProjectBlock()}>proyectos / </span>
                                                <span id="dirNameSubFolder" onClick={() => handleFarmsProjectBlock()}>{projectName} / </span>
                                                <span id="dirNamePredio">{dataFarm.properties.name}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <div className="row mt-3">
                                                <div className="col-12 p-1">
                                                    <h3>{dataFarm.properties.name}</h3>
                                                    <p>{dataFarm.properties.creation_date}</p>
                                                </div>
                                            </div>
                                            <div className="row my-1">
                                                <div className="col-5 p-1">
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fas fa-seedling"></i> Cultivo de {dataFarm.properties.crop}</span>
                                                    </div>
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-expand"></i> {parseFloat(dataFarm.properties.area).toFixed(2)} ha</span>
                                                    </div>
                                                </div>
                                                <div className="col-7 p-1">
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-location-dot"></i> Colombia | {dataFarm.properties.municipio}, {dataFarm.properties.departamento}</span>
                                                    </div>
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-compass"></i> {dataFarm.x}, {dataFarm.y}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div id="btnDetailPredio" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarDetailPredio} className="btn-card-inner btn-active d-flex justify-content-between align-items-center">Reportes</a>
                                                </div>
                                                <div id="btnAlerts" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarAlerts} className="btn-card-inner btn-not-active d-flex justify-content-between align-items-center">Alertas</a>
                                                </div>
                                                <div id="btnDetailDeterminantes" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarDetailDeterminantes} className="btn-card-inner btn-not-active d-flex justify-content-between align-items-center">Determinantes</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container cont-card-detail-predio">
                                        <div className="row mt-4">
                                            <div className="col-8 btn-card px-1">
                                                <div className="cont-next-eval">Próxima evaluación 2024</div>
                                            </div>
                                            {/*<div className="col-2 offset-2 d-flex justify-content-end">
                                                <div className="btn-card-inner btn-download">
                                                    <i className="bx bx-download"></i>
                                                </div>
                        </div>*/}
                                        </div>
                                        <div className="row mt-4">
                                            <strong className="p-1">Último reporte</strong>
                                        </div>
                                        <div className="row my-4">
                                            <div className="col-12 btn-card px-1">
                                                <div className="cont-last-report">
                                                    {dataReports.length > 0 && (
                                                        <>
                                                            <div className="col-5 offset-7 cont-label-valid">{dataReports[dataReports.length - 1].year}</div>
                                                            <div className="ms-4 pb-3 cont-inner-report">
                                                                <div><strong>Reporte {dataReports.length} </strong>{dataReports[dataReports.length - 1].year}</div>
                                                                <div>
                                                                    <img src={require("../assets/images/icons/icon_popup.png")} alt="Icon PopUp" className="icon-free-defores"></img>
                                                                    <span className={`text-${alertTotDesign}-defores`}>{alertTot}</span>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Área de bosque</strong>
                                                                        <span className="font-size-12 ms-1">{dataClassifications.forest_area} ha</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Área de no bosque</strong>
                                                                        <span className="font-size-12 ms-1">{dataClassifications.non_forest_area} ha</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Deforestación</strong>
                                                                        <span className="font-size-12 ms-1">{dataClassifications.no_data_area} ha</span>
                                                                    </div>
                                                                    {/*<div className="col-3 d-flex justify-content-end">
                                                                        <div className="btn-card-inner">
                                                                            <i className="bx bx-download"></i>
                                                                        </div>
                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {dataReports.length === 0 && (
                                                        <>
                                                            <div className="col-5 offset-7 cont-label-valid">2023</div>
                                                            <div className="ms-4 pb-3 cont-inner-report">
                                                                <div><strong>Reporte 1 </strong>2023</div>
                                                                <div>
                                                                    <img src={require("../assets/images/icons/icon_popup.png")} alt="Icon PopUp" className="icon-free-defores"></img>
                                                                    <span className={`text-high-defores`}>Alta deforestación</span>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Área de bosque</strong>
                                                                        <span className="font-size-12 ms-1">2520700 ha</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Área de no bosque</strong>
                                                                        <span className="font-size-12 ms-1">106500 ha</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Deforestación</strong>
                                                                        <span className="font-size-12 ms-1">2365 ha</span>
                                                                    </div>
                                                                    {/*<div className="col-3 d-flex justify-content-end">
                                                                        <div className="btn-card-inner">
                                                                            <i className="bx bx-download"></i>
                                                                        </div>
                                                    </div>*/}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {dataReports.length > 0 && (
                                            <>
                                                <div className="row mt-1">
                                                    <div className="col-6 p-0">
                                                        <strong>Histórico de reportes</strong>
                                                    </div>
                                                    <div className="col-6 p-0 text-end pe-3">
                                                        <span>{dataReports.length} resultados</span>
                                                    </div>
                                                </div>
                                                
                                                {dataReports.map((report, key) => (
                                                    <div key={key} className="row card-folder py-1">
                                                        <div className="col-5 d-flex align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <input className="me-2" type="checkbox" />
                                                                <div className="text-count-predios ms-2">
                                                                    <strong>Reporte {key + 1} | {report.year}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-5 d-flex align-items-center">
                                                            <div className={`label-deforest-history-${alertTotDesign}`}>{alertTot}</div>
                                                        </div>
                                                        <div className="col-2 d-flex align-items-center justify-content-end">
                                                            <div className="d-inline-block icon-list-predios">
                                                                <i className="bx bx-download" onClick={() => handleDownloadClick(report.url, report.year)}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}

                                        {dataReports.length === 0 && (
                                            <>
                                                <div className="row mt-1">
                                                    <div className="col-6 p-0">
                                                        <strong>Histórico de reportes</strong>
                                                    </div>
                                                    <div className="col-6 p-0 text-end pe-3">
                                                        <span>1 resultados</span>
                                                    </div>
                                                </div>
                                                <div className="row card-folder py-1">
                                                    <div className="col-5 d-flex align-items-center">
                                                        <div className="d-flex align-items-center">
                                                            <input className="me-2" type="checkbox" />
                                                            <div className="text-count-predios ms-2">
                                                                <strong>Reporte 1 | 2023</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-5 d-flex align-items-center">
                                                        <div className={`label-deforest-history-high`}>Alta deforestación</div>
                                                    </div>
                                                    <div className="col-2 d-flex align-items-center justify-content-end">
                                                        <div className="d-inline-block icon-list-predios">
                                                            <i className="bx bx-download" onClick={() => handleDownloadClick("https://folia-products.s3.amazonaws.com/due_diligence/2-carlos/2-dummy/55-pachanga/2023/report_55-pachanga_2022-2023.pdf?AWSAccessKeyId=ASIA2ENB2W4D2N42SRUR&Signature=vLz9qjzVX0LGcV4JbDECrlOcGtA%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEF4aCXVzLXdlc3QtMiJGMEQCIBpuAbklY7EK0P0nEDj6Ywyays3QCpRKQ7bdjZFqjLFbAiA60VGSOt6f9jQLguMgFxw8ByijJgmOdgPm6iX7H%2F%2Bc4iruAghnEAAaDDY5NjY2MDk2NTEyNyIMK8G8J5xPIxB00uJzKssCGPmoUbiDrdbEHrXMU%2FhvK0byeCGaxczrrZZ96waYTYjo68DoLrWSmI88xn%2FyojwmPzjcnZWV5KdGTuInxsuH1BuMpqtthDb31GE8U%2FPckt69uIKHsJp4etlHISGHyqFS0GeXuMD1nriddCXQrdQx6ud43MbPdSZXHvTSEOGnpdZ%2FwLTdnQ%2FPIASPX3SwiuYsuLaN7lP09PtoD0alOfeIhVaMAVQ4jNqYX65yuusZlwl2IQfRzwFtW5Ue6otrnQVd78OyNvr0bChY%2B%2Buy5XrHV%2FwzlNCiEWWucMMAhLHws0CUjPJd8e5%2BMpd%2BN6Mw71cAXjPdO9eoLlv8Zfs3X50vYhUkKXydqiKwBMBQ6Ffznr%2FrkiDlofWtha0Av27SJHgQyEFM4VF4JJENcK7PxY1teqwTSoV8%2B3aER7g0wWO1TNK1ODqd1dd027uPSzC6vsivBjqfASNSoBVyKh8Y42cMsu%2Bx9VMN14g%2Bq3umzeIqy%2BXRJF0TbMDJRq2yTEnNmBinJsodkK89xwsyDEh0jap6F4oqV7tRYARWX%2BTxS2ZgUCF%2BLKQ%2FdXJCW%2FXG3TzGxhrtQSmUBJkBWTQvR4iNux7JSpS8xh0jOuZT%2Ft10ZOGp8h4NT54Q2w4K9GIrPDMijxIIOXmd8gTp4Svycriv2yW5TEHfPA%3D%3D&Expires=1710370303", "2023")}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>


                                <div id="contPanelListDetailPredioAlerts">
                                    <div className="cont-header-detail-predio">
                                        <div className="row">
                                            <p className="px-1">
                                                <span id="dirNameFolder" onClick={() => handleProjectBlock()}>proyectos / </span>
                                                <span id="dirNameSubFolder" onClick={() => handleFarmsProjectBlock()}>{projectName} / </span>
                                                <span id="dirNamePredio">{dataFarm.properties.name}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <div className="row mt-3">
                                                <div className="col-12 p-1">
                                                    <h3>{dataFarm.properties.name}</h3>
                                                    <p>{dataFarm.properties.creation_date}</p>
                                                </div>
                                            </div>
                                            <div className="row my-1">
                                                <div className="col-5 p-1">
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fas fa-seedling"></i> Cultivo de {dataFarm.properties.crop}</span>
                                                    </div>
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-expand"></i> {parseFloat(dataFarm.properties.area).toFixed(2)} ha</span>
                                                    </div>
                                                </div>
                                                <div className="col-7 p-1">
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-location-dot"></i> Colombia | {dataFarm.properties.municipio}, {dataFarm.properties.departamento}</span>
                                                    </div>
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-compass"></i> {dataFarm.x}, {dataFarm.y}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div id="btnDetailPredio" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarDetailPredio} className="btn-card-inner btn-not-active d-flex justify-content-between align-items-center">Reportes</a>
                                                </div>
                                                <div id="btnAlerts" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarAlerts} className="btn-card-inner btn-active d-flex justify-content-between align-items-center">Alertas</a>
                                                </div>
                                                <div id="btnDetailDeterminantes" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarDetailDeterminantes} className="btn-card-inner btn-not-active d-flex justify-content-between align-items-center">Determinantes</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container cont-card-detail-predio">
                                        <div className="row mt-4">
                                            <strong className="p-1">Última alerta</strong>
                                        </div>
                                        
                                        {dataAlerts.length > 0 && (
                                            <>
                                                <div className={`row card-last-alert-${getAlertClass()}`}>
                                                    <div className={`col-1 pt-3 d-flex justify-content-center icon-last-alert-${getAlertClass()}`}>
                                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                                    </div>
                                                    <div className="col-11 mt-2">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <strong>{dataAlerts[dataAlerts.length - 1].category_description}</strong>
                                                            </div>
                                                            <div className="col-6 d-flex justify-content-end">
                                                                <strong className="ms-4">{dataAlerts[dataAlerts.length - 1].datetime}</strong>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <ul className="mb-0">
                                                                <li>Causa: {dataAlerts[dataAlerts.length - 1].cause ? dataAlerts[dataAlerts.length - 1].cause : "Sin información de causas"}</li>
                                                                <li>Descripción: {dataAlerts[dataAlerts.length - 1].description ? dataAlerts[dataAlerts.length - 1].description : "Sin información de descrićión"}</li>
                                                                <li>Sugerencia: {dataAlerts[dataAlerts.length - 1].suggestions ? dataAlerts[dataAlerts.length - 1].suggestions : "Sin información de sugerencias"}</li>
                                                            </ul>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-end mb-2">
                                                            {dataAlerts[dataAlerts.length - 1].notes.length} <div className="ms-1 item-circle-minor d-inline-block"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row card-interactions-alert py-3">
                                                    {dataAlerts[dataAlerts.length - 1].notes.map((note, key) => (
                                                        <div className="card-interaction col-10 offset-1 my-2 py-2">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="ms-1 item-circle-minor d-inline-block item-circle-blue"></div>
                                                                    <span>{note.author}</span>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <span>{note.datetime}</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="d-flex align-items-center">
                                                                    <strong>{note.note}</strong>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <i className="fa-solid fa-trash-can"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <input className="col-10 offset-1 mt-1 py-1 input-interaction" type="text"
                                                        placeholder="Agrega un comentario"></input>
                                                </div>
                                            </>
                                        )}

                                        {dataAlerts.length === 0 && (
                                            <>
                                                <div className={`row card-last-alert-high`}>
                                                    <div className={`col-1 pt-3 d-flex justify-content-center icon-last-alert-high`}>
                                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                                    </div>
                                                    <div className="col-11 mt-2">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <strong>Riesgo alto</strong>
                                                            </div>
                                                            <div className="col-6 d-flex justify-content-end">
                                                                <strong className="ms-4">2023-11-20T20:26:12</strong>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <ul className="mb-0">
                                                                <li>Causa: Cambio pastos</li>
                                                                <li>Descripción: Deforestación al interior del predio aproximadamente 72 hectareas</li>
                                                                <li>Sugerencia: Revisar politica de la mpresa para casos dee deforestación confirmada</li>
                                                            </ul>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-end mb-2">
                                                            1 <div className="ms-1 item-circle-minor d-inline-block"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row card-interactions-alert py-3">
                                                    <div className="card-interaction col-10 offset-1 my-2 py-2">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <div className="ms-1 item-circle-minor d-inline-block item-circle-blue"></div>
                                                                <span>Carlos Pedraza</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <span>2023-11-22T17:31:00.235807</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <strong>Adjuntaremos la evidencia necesaria</strong>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <i className="fa-solid fa-trash-can"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input className="col-10 offset-1 mt-1 py-1 input-interaction" type="text"
                                                        placeholder="Agrega un comentario"></input>
                                                </div>
                                            </>
                                        )}
                                        

                                        <div className="row mt-1">
                                            <div className="col-6 p-0">
                                                <strong>Histórico de alertas</strong>
                                            </div>
                                            <div className="col-6 p-0 text-end pe-3">
                                                <span>{dataAlerts.length}</span>
                                            </div>
                                        </div>
                                        {dataAlerts.map((alert, key) => (
                                            <div key={key} className={`row card-alert-${getAlertClass()}`}>
                                                <div className={`col-1 d-flex align-items-center justify-content-center icon-card-alert-${getAlertClass()}`}>
                                                    <i className="fa-solid fa-circle-exclamation"></i>
                                                </div>
                                                <div className="col-9 d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="text-count-predios ms-2">
                                                            <strong>{alert.category_description}</strong>
                                                            <span className="ms-4">{alert.datetime}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 d-flex align-items-center justify-content-end">
                                                    {alert.notes.length} <div className="ms-1 item-circle-minor d-inline-block"></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div id="contPanelListDetailPredioDeterminantes">
                                    <div className="cont-header-detail-predio">
                                        <div className="row">
                                            <p className="px-1">
                                                <span id="dirNameFolder" onClick={() => handleProjectBlock()}>proyectos / </span>
                                                <span id="dirNameSubFolder" onClick={() => handleFarmsProjectBlock()}>{projectName} / </span>
                                                <span id="dirNamePredio">{dataFarm.properties.name}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <div className="row mt-3">
                                                <div className="col-12 p-1">
                                                    <h3>{dataFarm.properties.name}</h3>
                                                    <p>{dataFarm.properties.creation_date}</p>
                                                </div>
                                            </div>
                                            <div className="row my-1">
                                                <div className="col-5 p-1">
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fas fa-seedling"></i> Cultivo de {dataFarm.properties.crop}</span>
                                                    </div>
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-expand"></i> {parseFloat(dataFarm.properties.area).toFixed(2)} ha</span>
                                                    </div>
                                                </div>
                                                <div className="col-7 p-1">
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-location-dot"></i> Colombia | {dataFarm.properties.municipio}, {dataFarm.properties.departamento}</span>
                                                    </div>
                                                    <div className="cont-item-popup">
                                                        <span className="item-popup"><i className="fa-solid fa-compass"></i> {dataFarm.x}, {dataFarm.y}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-4">
                                                <div id="btnDetailPredio" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarDetailPredio} className="btn-card-inner btn-not-active d-flex justify-content-between align-items-center">Reportes</a>
                                                </div>
                                                <div id="btnAlerts" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarAlerts} className="btn-card-inner btn-not-active d-flex justify-content-between align-items-center">Alertas</a>
                                                </div>
                                                <div id="btnDetailDeterminantes" className="col-4 btn-card px-1">
                                                    <a type="button" onClick={toggleSidebarDetailDeterminantes} className="btn-card-inner btn-active d-flex justify-content-between align-items-center">Determinantes</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container cont-card-detail-predio">
                                        <div className="row mt-4">
                                            <strong className="p-1">Consejos comunitarios</strong>
                                        </div>
                                        
                                        {dataDeterminantes?.["Consejos Comunitarios"] && dataDeterminantes["Consejos Comunitarios"].length > 0 ? (
                                            <>
                                                {dataDeterminantes["Consejos Comunitarios"].map((dataDeter, index) => (
                                                    <div className="row my-4" key={index}>
                                                        <div className="col-12 btn-card px-1">
                                                            <div className="cont-last-report">
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Área intersección: </strong>
                                                                        <span className="font-size-12 ms-1">1.256 ha</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Nombre: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.nombre}</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Pueblo: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.pueblo}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Tipo acto administrativo: </strong>
                                                                        <span className="font-size-12 ms-1">
                                                                            {dataDeter.properties.tipo_acto_administrativo}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Número acto administrativo: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.numero_acto_administrativo}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Fecha acto administrativo: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.fecha_acto_administrativo}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <div className="row my-4">
                                                    <div className="col-12 btn-card px-1">
                                                        <div className="cont-last-report">
                                                                <strong className="font-size-12">No existen consejos Comunitarios en este predio</strong>                                                      
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="row mt-4">
                                            <strong className="p-1">Resguardos indigenas</strong>
                                        </div>
                                        
                                        {dataDeterminantes?.["Resguardos Indígenas"] && dataDeterminantes["Resguardos Indígenas"].length > 0 ? (
                                            <>
                                                {dataDeterminantes["Resguardos Indígenas"].map((dataDeter, index) => (
                                                    <div className="row my-4" key={index}>
                                                        <div className="col-12 btn-card px-1">
                                                            <div className="cont-last-report">
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Área intersección: </strong>
                                                                        <span className="font-size-12 ms-1">1.256 ha</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Nombre: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.nombre}</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Pueblo: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.pueblo}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Tipo acto administrativo: </strong>
                                                                        <span className="font-size-12 ms-1">
                                                                            {dataDeter.properties.tipo_acto_administrativo}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Número acto administrativo: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.numero_acto_administrativo}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Fecha acto administrativo: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.fecha_acto_administrativo}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <div className="row my-4">
                                                    <div className="col-12 btn-card px-1">
                                                        <div className="cont-last-report">
                                                                <strong className="font-size-12">No existen resguardos indigenas en este predio</strong>                                                      
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="row mt-4">
                                            <strong className="p-1">Áreas protegidas</strong>
                                        </div>
                                        {dataDeterminantes?.["Áreas Protegidas"] && dataDeterminantes["Áreas Protegidas"].length > 0 ? (
                                            <>
                                                {dataDeterminantes["Áreas Protegidas"].map((dataDeter, index) => (
                                                    <div className="row my-4" key={index}>
                                                        <div className="col-12 btn-card px-1">
                                                            <div className="cont-last-report">
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Área intersección: </strong>
                                                                        <span className="font-size-12 ms-1">1.256 ha</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Nombre: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.nombre}</span>
                                                                    </div>
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Categoría: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.categoria}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Url: </strong>
                                                                        <span className="font-size-12 ms-1">
                                                                            <a href={dataDeter.properties.url} target="_blank" rel="noopener noreferrer">
                                                                                {dataDeter.properties.url}
                                                                            </a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Organización: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.organizaci}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-3 d-flex align-items-center">
                                                                        <strong className="font-size-12">Resolución: </strong>
                                                                        <span className="font-size-12 ms-1">{dataDeter.properties.resolucion}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <div className="row my-4">
                                                    <div className="col-12 btn-card px-1">
                                                        <div className="cont-last-report">
                                                                <strong className="font-size-12">No existen áreas protegidas en este predio</strong>                                                      
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Geovisor;
