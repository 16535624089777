import { createGlobalStyle } from "styled-components";
import imagenFondo from "../assets/images/fondo.jpeg";


export default createGlobalStyle`
  #body-pd {
    background-color: #e6e6e6;
    font-family: "Poppins-Regular";
    color: ${({ theme }) => theme.colors.text};
    margin: 0; /* Elimina los márgenes predeterminados del body */
    padding: 0; /* Elimina el relleno predeterminado del body */
    width: 100%;
    height: 100%;
  }

  #fondo-con-imagen {
    background-image: url(${imagenFondo});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 85%; /* Altura total menos el margen inferior */
    width: 100%;
  }

  .fondo-con-imagen-home {
    height: 100% !important;
  }

  .partners-container {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #061437;
  }

  .partners-logo {
    height: 100px;
  }

  @media (max-width: 768px) {
    #fondo-con-imagen {
      height: auto; /* Cambia a altura automática en pantallas más pequeñas */
    }
  }
`;