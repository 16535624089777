import React, { useState, useEffect, useMemo } from 'react';
import { Sidebar } from "../components"
import { useLocation, useNavigate } from 'react-router-dom';
import "./table.css"; // Estilos del componente
import { DataGrid } from '@mui/x-data-grid';

const DashHome = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState('Inicio');
    const [data, setData] = useState(JSON.parse(localStorage.getItem('dataFarmsProject')));
    const [dataAlerts, setDataAlerts] = useState(JSON.parse(localStorage.getItem('infoAlerts')));
    const [dataReports, setDataReports] = useState(JSON.parse(localStorage.getItem('infoReports')));
    const [totalFarms, getTotalFarms] = useState(JSON.parse(localStorage.getItem('totalFarms')));
    const [totalAlerts, getTotalAlerts] = useState(JSON.parse(localStorage.getItem('totalAlerts')));
    const [totalReports, setTotalReports] = useState(JSON.parse(localStorage.getItem('totalReports')));
    const [totalChange, setTotalChange] = useState(JSON.parse(localStorage.getItem('totalChange')));
    const [totalClassification, setTotalClassification] = useState(JSON.parse(localStorage.getItem('totalClassification')));

    const handleNavigate = () => {
        const data = state.data;
        navigate('/PureMap', { state: { data } });
    };

    const [tokenArcgis, setTokenArcgis] = useState(null);
    const [dataFeaturePredio, setDataFeaturePredio] = useState(null);
    const [dataFeatureDeforestacion, setDataFeatureDeforestacion] = useState(null);
    const [dataFeatureAreaTotal, setDataFeatureAreaTotal] = useState(null);
    const [dataFeatureProporcion, setDataFeatureProporcion] = useState(null);
    const [dataFeaturePreAreaProt, setDataFeaturePreAreaProt] = useState(null);
    const [dataFeaturePreFrontera, setDataFeaturePreFrontera] = useState(null);
    const [dataFeatureFoliaRep, setDataFeatureFoliaRep] = useState(null);
    const [totalPresente, setTotalPresente] = useState(null);
    const [totalAusente, setTotalAusente] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const referer = encodeURIComponent('https://www.arcgis.com');
                const tokenUrl = `https://www.arcgis.com/sharing/generateToken?f=json&referer=${referer}`;
                const response = await fetch(tokenUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({
                        'username': 'jcgomez_dreamgis',
                        'password': 'Camilo123+*',
                        'expiration': '60'
                    })
                });
        
                if (!response.ok) {
                    throw new Error('Failed to generate token');
                }
        
                const data = await response.json();
                const generatedToken = data.token;
                localStorage.setItem('tokenArcgisNew', generatedToken);
                setTokenArcgis(generatedToken);

                const urlFeaturePredio = `https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Predios_an%C3%A1lisis_2020_2023/FeatureServer/0/query?where=1%3D1`;
                const urlFeatureDeforestacion = `https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Deforestaci%C3%B3n_pres_ause_2020_2023/FeatureServer/0/query?where=1%3D1`;
                const urlFeatureAreaTotal = `https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Area_total_perdida_2020_2023/FeatureServer/0/query?where=1%3D1`;
                const urlFeatureProporcion = `https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Proporcion_perdida_2020_2023/FeatureServer/0/query?where=1%3D1`;
                const urlFeaturePreAreaProt = `https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Predios_en_areas_protegidas/FeatureServer/0/query?where=1%3D1`;
                const urlFeaturePreFrontera = `https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Predios_frontera_agricola/FeatureServer/0/query?where=1%3D1`;
                const urlFeatureFoliaRep = `https://services7.arcgis.com/WVbSavOSaZImJjic/arcgis/rest/services/Chuculat_FOLIA_Report/FeatureServer/0/query?where=1%3D1`;

                const contUrl = `&token=${generatedToken}&outFields=*&f=pjson`

                const response1 = await fetch(`${urlFeaturePredio}${contUrl}`, {
                  method: 'GET',
                });
                if (response1) {
                  const data1 = await response1.json();
                  const fields1 = data1.features;
                  setDataFeaturePredio(fields1);
                } else {
                    console.error('Error al obtener los campos:', response1.statusText);
                }


                const response2 = await fetch(`${urlFeatureDeforestacion}${contUrl}`, {
                    method: 'GET',
                  });
                  if (response2) {
                    const data2 = await response2.json();
                    const fields2 = data2.features;
                    let totalAusenteCount = 0;
                    let totalPresenteCount = 0;
                    for(var key in fields2){
                        
                        if(fields2[key].attributes.project_id == "2"){
                            totalPresenteCount = totalPresenteCount +1;
                        }else{
                            totalAusenteCount = totalAusenteCount +1;
                        }
                    }
                    setTotalAusente(totalAusenteCount);
                    setTotalPresente(totalPresenteCount);
                    setDataFeatureDeforestacion(fields2);
                    console.log("FIELDS2", fields2)
                  } else {
                      console.error('Error al obtener los campos:', response2.statusText);
                  }

                const response3 = await fetch(`${urlFeatureAreaTotal}${contUrl}`, {
                    method: 'GET',
                  });
                  if (response3) {
                    const data3 = await response3.json();
                    const fields3 = data3.features;
                    setDataFeatureAreaTotal(fields3);
                    console.log("FIELDS3", fields3)
                  } else {
                      console.error('Error al obtener los campos:', response3.statusText);
                  }


                const response4 = await fetch(`${urlFeatureProporcion}${contUrl}`, {
                    method: 'GET',
                  });
                  if (response4) {
                    const data4 = await response4.json();
                    const fields4 = data4.features;
                    setDataFeatureProporcion(fields4);
                    console.log("FIELDS4", fields4)
                  } else {
                      console.error('Error al obtener los campos:', response4.statusText);
                  }

                const response5 = await fetch(`${urlFeaturePreAreaProt}${contUrl}`, {
                    method: 'GET',
                  });
                  if (response5) {
                    const data5 = await response5.json();
                    const fields5 = data5.features;
                    setDataFeaturePreAreaProt(fields5);
                    console.log("FIELDS5", fields5)
                  } else {
                      console.error('Error al obtener los campos:', response5.statusText);
                  }

                const response6 = await fetch(`${urlFeaturePreFrontera}${contUrl}`, {
                    method: 'GET',
                  });
                  if (response6) {
                    const data6 = await response6.json();
                    const fields6 = data6.features;
                    setDataFeaturePreFrontera(fields6);
                    console.log("FIELDS6", fields6)
                  } else {
                      console.error('Error al obtener los campos:', response6.statusText);
                  }

                const response7 = await fetch(`${urlFeatureFoliaRep}${contUrl}`, {
                    method: 'GET',
                  });
                  if (response7) {
                    const data7 = await response7.json();
                    const fields7 = data7.features;
                    setDataFeatureFoliaRep(fields7);
                    console.log("FIELDS7", fields7)
                  } else {
                      console.error('Error al obtener los campos:', response7.statusText);
                  }
            } catch (error) {
                console.error('Error generating token:', error);
            }
        };

        fetchToken();
    }, []);

    useEffect(() => {
        if (state === null) {
            navigate("/");
        }
    }, []);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    const getColumnsAndRows = () => {
        let columns = [];
        let rows = [];

        // Decide dynamically based on selectedTab
        if (selectedTab === 'Predios') {
            columns = [
                { field: 'id', headerName: 'ID', flex: 0.1, hide:true },
                { field: 'nombre', headerName: 'Nombre', flex: 2 },
                { field: 'departamento', headerName: 'Departamento', flex: 2 },
                { field: 'municipio', headerName: 'Municipio', flex: 3 },
                { field: 'cultivo', headerName: 'Cultivo', flex: 1 },
                {
                  field: 'area',
                  headerName: 'Area',
                  type: 'number',
                  flex: 2,
                }
                /*{
                  field: 'fullName',
                  headerName: 'Full name',
                  description: 'This column has a value getter and is not sortable.',
                  sortable: false,
                  width: 160,
                  valueGetter: (params) =>
                    `${params.row.firstName || ''} ${params.row.lastName || ''}`,
                },*/
              ];
              
              
              for(var key in dataFeaturePredio){
                const objectData = {
                    id: dataFeaturePredio[key].attributes.FID,
                    nombre: dataFeaturePredio[key].attributes.name,
                    departamento: dataFeaturePredio[key].attributes.departamen,
                    municipio: dataFeaturePredio[key].attributes.municipio,
                    cultivo: dataFeaturePredio[key].attributes.crop,
                    area: parseFloat(dataFeaturePredio[key].attributes.area).toFixed(2)
                }
                rows.push(objectData);
              }
              
        } else if (selectedTab === 'Deforestacion') {
            columns = [
                { field: 'id', headerName: 'ID', flex: 0.1, hide:true},
                { field: 'cedula_catastral', headerName: 'Cédula catastral', flex: 2 },
                { field: 'nombre', headerName: 'Nombre', flex: 1 },
                { field: 'departamento', headerName: 'Departamento', flex: 2 },
                { field: 'municipio', headerName: 'Municipio', flex: 2 },
                { field: 'deforestacion', headerName: 'Deforestación', flex: 1 }
              ];
              
              for(var key in dataFeatureDeforestacion){
                let deforestacion = 'Ausente';
                if(dataFeatureDeforestacion[key].attributes.project_id == "2"){
                    deforestacion = 'Presente';
                }
                const objectData = {
                    id: dataFeatureDeforestacion[key].attributes.FID,
                    cedula_catastral: dataFeatureDeforestacion[key].attributes.cadastral_,
                    nombre: dataFeatureDeforestacion[key].attributes.name,
                    departamento: dataFeatureDeforestacion[key].attributes.departamen,
                    municipio: dataFeatureDeforestacion[key].attributes.municipio,
                    deforestacion: deforestacion
                }
                rows.push(objectData);
              }
        }else if (selectedTab === 'AreaTotal') {
            columns = [
                { field: 'id', headerName: 'ID', flex: 0.1, hide:true},
                { field: 'cedula_catastral', headerName: 'Cédula catastral', flex: 2 },
                { field: 'nombre', headerName: 'Nombre', flex: 1 },
                { field: 'departamento', headerName: 'Departamento', flex: 2 },
                { field: 'municipio', headerName: 'Municipio', flex: 2 },
                { field: 'riesgo', headerName: 'Riesgo', flex: 1 }
              ];
              
              for(var key in dataFeatureAreaTotal){
                const objectData = {
                    id: dataFeatureAreaTotal[key].attributes.FID,
                    cedula_catastral: dataFeatureAreaTotal[key].attributes.cadastral_,
                    nombre: dataFeatureAreaTotal[key].attributes.name,
                    departamento: dataFeatureAreaTotal[key].attributes.departamen,
                    municipio: dataFeatureAreaTotal[key].attributes.municipio,
                    riesgo: dataFeatureAreaTotal[key].attributes.A_DEF_Risk
                }
                rows.push(objectData);
              }
        }else if (selectedTab === 'proporcion') {
            columns = [
                { field: 'id', headerName: 'ID', flex: 0.1, hide:true},
                { field: 'cedula_catastral', headerName: 'Cédula catastral', flex: 2 },
                { field: 'nombre', headerName: 'Nombre', flex: 1 },
                { field: 'departamento', headerName: 'Departamento', flex: 2 },
                { field: 'municipio', headerName: 'Municipio', flex: 2 },
                { field: 'riesgo', headerName: 'Riesgo', flex: 1 },
                { field: 'proporcion', headerName: 'Proporción', flex: 1 }
              ];
              
              for(var key in dataFeatureProporcion){
                const objectData = {
                    id: dataFeatureProporcion[key].attributes.FID,
                    cedula_catastral: dataFeatureProporcion[key].attributes.cadastral_,
                    nombre: dataFeatureProporcion[key].attributes.name,
                    departamento: dataFeatureProporcion[key].attributes.departamen,
                    municipio: dataFeatureProporcion[key].attributes.municipio,
                    riesgo: dataFeatureProporcion[key].attributes.A_DEF_Risk,
                    proporcion: dataFeatureProporcion[key].attributes.Prop_Risk
                }
                rows.push(objectData);
              }
        }else if (selectedTab === 'vsAreaProt') {
            columns = [
                { field: 'id', headerName: 'ID', flex: 0.1, hide:true},
                { field: 'cedula_catastral', headerName: 'Cédula catastral', flex: 2 },
                { field: 'nombre', headerName: 'Nombre', flex: 1 },
                { field: 'departamento', headerName: 'Departamento', flex: 2 },
                { field: 'municipio', headerName: 'Municipio', flex: 2 },
                { field: 'categoria', headerName: 'Categoria', flex: 2 },
              ];
              
              for(var key in dataFeaturePreAreaProt){
                const objectData = {
                    id: dataFeaturePreAreaProt[key].attributes.FID,
                    cedula_catastral: dataFeaturePreAreaProt[key].attributes.cadastral_,
                    nombre: dataFeaturePreAreaProt[key].attributes.name,
                    departamento: dataFeaturePreAreaProt[key].attributes.departamen,
                    municipio: dataFeaturePreAreaProt[key].attributes.municipio,
                    categoria: dataFeaturePreAreaProt[key].attributes.categoria,
                }
                rows.push(objectData);
              }
        }else if (selectedTab === 'vsFrontAgr') {
            columns = [
                { field: 'id', headerName: 'ID', flex: 0.1, hide:true},
                { field: 'cedula_catastral', headerName: 'Cédula catastral', flex: 2 },
                { field: 'nombre', headerName: 'Nombre', flex: 1 },
                { field: 'departamento', headerName: 'Departamento', flex: 2 },
                { field: 'municipio', headerName: 'Municipio', flex: 2 },
                { field: 'elemento', headerName: 'Elemento', flex: 2 },
              ];
              
              for(var key in dataFeaturePreFrontera){
                const objectData = {
                    id: dataFeaturePreFrontera[key].attributes.FID,
                    cedula_catastral: dataFeaturePreFrontera[key].attributes.cadastral_,
                    nombre: dataFeaturePreFrontera[key].attributes.name,
                    departamento: dataFeaturePreFrontera[key].attributes.departamen,
                    municipio: dataFeaturePreFrontera[key].attributes.municipio,
                    elemento: dataFeaturePreFrontera[key].attributes.elemento,
                }
                rows.push(objectData);
              }
        }else if (selectedTab === 'repDefo') {
            columns = [
                { field: 'id', headerName: 'ID', flex: 0.1, hide:true },
                { field: 'nombre', headerName: 'Nombre', flex: 2 },
                { field: 'departamento', headerName: 'Departamento', flex: 2 },
                { field: 'municipio', headerName: 'Municipio', flex: 3 },
                { field: 'cultivo', headerName: 'Cultivo', flex: 1 },
                { field: 'cubrir', headerName: 'Cubrir', flex: 1 },
              ];
              
              
              for(var key in dataFeatureFoliaRep){
                const objectData = {
                    id: dataFeatureFoliaRep[key].attributes.FID,
                    nombre: dataFeatureFoliaRep[key].attributes.name,
                    departamento: dataFeatureFoliaRep[key].attributes.departamen,
                    municipio: dataFeatureFoliaRep[key].attributes.municipio,
                    cultivo: dataFeatureFoliaRep[key].attributes.crop,
                    cubrir: dataFeatureFoliaRep[key].attributes.cover
                }
                rows.push(objectData);
              }
        }
        

        return { columns, rows };
    };

    const { columns, rows } = getColumnsAndRows();

    return (
        <div className="conts-dash-home d-flex flex-column">
            {/* {loadingModal && <LoadingModal />} */}
            <Sidebar navTitle={"ESTUDIOS"} />

            <div className="cont-dash">
                <div className="row mt-4">
                    <div className="col-3 offset-1">
                        <a id="btnSeeMap" type="button" className="btn-popup" onClick={handleNavigate}>MOSTRAR EN MAPA</a>
                    </div>
                </div>
                <div className="row">
                    <div className="cont-btn-side-left-dash col-2 offset-1">
                        <button className={`btn-side-left-dash btn-side-left-dash${selectedTab === 'Inicio' ? '-selected' : ''}`} onClick={() => handleTabChange('Inicio')}>Inicio</button>
                        <button className={`btn-side-left-dash btn-side-left-dash${selectedTab === 'Predios' ? '-selected' : ''}`} onClick={() => handleTabChange('Predios')}>Mis predios</button>
                        <button className={`btn-side-left-dash btn-side-left-dash${selectedTab === 'Deforestacion' ? '-selected' : ''}`} onClick={() => handleTabChange('Deforestacion')}>Deforestacion</button>
                        <button className={`btn-side-left-dash btn-side-left-dash${selectedTab === 'AreaTotal' ? '-selected' : ''}`} onClick={() => handleTabChange('AreaTotal')}>Área total deforestación</button>
                        <button className={`btn-side-left-dash btn-side-left-dash${selectedTab === 'proporcion' ? '-selected' : ''}`} onClick={() => handleTabChange('proporcion')}>Proporción deforestación</button>
                        <button className={`btn-side-left-dash btn-side-left-dash${selectedTab === 'vsAreaProt' ? '-selected' : ''}`} onClick={() => handleTabChange('vsAreaProt')}>Vs áreas protegidas</button>
                        <button className={`btn-side-left-dash btn-side-left-dash${selectedTab === 'vsFrontAgr' ? '-selected' : ''}`} onClick={() => handleTabChange('vsFrontAgr')}>Vs frontera agrícola</button>
                        <button className={`btn-side-left-dash btn-side-left-dash${selectedTab === 'repDefo' ? '-selected' : ''}`} onClick={() => handleTabChange('repDefo')}>Reporte Cero Deforestación</button>
                    </div>
                    {selectedTab === 'Inicio' && (
                        <div className="col-8 cont-panels mt-5">
                            <div className="row">
                                <div className="item-dash col-6 doble-item-dash">
                                {dataFeaturePredio && (
                                    <p><b># Total de predios analizados:</b> {dataFeaturePredio.length} </p>
                                )}                                
                                </div>
                                <div className="item-dash col-3">
                                    <p>+ Agregar predio</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="item-dash col-3 item-properties">
                                    {totalAusente && (
                                        <p><b># Total de predios ausente deforestación:</b> {totalAusente}</p>
                                    )}
                                </div>
                                <div className="item-dash col-3 item-reports">
                                    {dataFeaturePreAreaProt && (
                                        <p><b># Total de predios vs área protegida:</b> {dataFeaturePreAreaProt.length} </p>
                                    )}
                                    {dataFeaturePreFrontera && (
                                        <p><b># Total de predios vs frontera agrícola:</b> {dataFeaturePreFrontera.length} </p>
                                    )}
                                </div>
                                <div className="item-dash col-3 item-alerts">
                                    {totalPresente && (
                                        <p><b># Total de predios presente deforestación:</b> {totalPresente}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {selectedTab === 'Deforestacion' && (
                        <div className="col-8 cont-panels mt-5">
                            <div className="my-destinations-container">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                            </div>
                        </div>
                    )}
                    {selectedTab === 'Predios' && (
                        <div className="col-8 cont-panels mt-5">
                            <div className="my-destinations-container">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                            </div>
                        </div>
                    )}
                    {selectedTab === 'AreaTotal' && (
                        <div className="col-8 cont-panels mt-5">
                            <div className="my-destinations-container">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                            </div>
                        </div>
                    )}
                     {selectedTab === 'proporcion' && (
                        <div className="col-8 cont-panels mt-5">
                            <div className="my-destinations-container">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                            </div>
                        </div>
                    )}
                    {selectedTab === 'vsAreaProt' && (
                        <div className="col-8 cont-panels mt-5">
                            <div className="my-destinations-container">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                            </div>
                        </div>
                    )}
                    {selectedTab === 'vsFrontAgr' && (
                        <div className="col-8 cont-panels mt-5">
                            <div className="my-destinations-container">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                            </div>
                        </div>
                    )}
                    {selectedTab === 'repDefo' && (
                        <div className="col-8 cont-panels mt-5">
                            <div className="my-destinations-container">
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                            />
                            </div>
                        </div>
                    )}
                    
                    <div className="col-1"></div>
                </div>
            </div>
        </div>
    );
};

export default DashHome;
