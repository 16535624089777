import React from "react";
import { Home, Status, Settings } from "../../components/Auth";
import { Account } from "../../components/Auth/Account"
// import Status from "../../components/Auth/Status"
// import Settings from "../../components/Auth/Settings"

const AuthHome = () => {
    return (
        <Account>
            <Home />
        </Account>
    );
};

export default AuthHome;
