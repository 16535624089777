import React from "react";
import { Login, Status, Settings } from "../../components/Auth";
import { Account } from "../../components/Auth/Account"
// import Status from "../../components/Auth/Status"
// import Settings from "../../components/Auth/Settings"

const AuthLogin = () => {
    return (
        <Account>
            <div className="container">
                <div className="row">
                    <header className="App-header">
                        <h1><Status /></h1>
                    </header>
                    <Login />
                    <Settings />
                </div>
            </div>
        </Account>
    );
};

export default AuthLogin;
