import React from "react";
// import Signup from "../../components/Auth/Signup";
import { Signup } from "../../components/Auth";

const AuthSignup = () => {
    return (
        <div className="container">
            <div className="row">
                <header className="App-header">
                    <h1>Crear Usuario</h1>
                </header>
                <Signup />
            </div>
        </div>
    );
};

export default AuthSignup;
