import React, { useState, useEffect, useContext } from 'react';
import { Sidebar } from "../components";
import { Modal, Button } from "react-bootstrap";
import { AccountContext } from '../components/Auth/Account';
import { useNavigate } from "react-router-dom";
import bannerFondo from "../assets/videos/banner_actu.mp4";
import iconPlus from "../assets/images/icons/plus.svg";
import imgNoticia1 from "../assets/images/news/Noticia_1.png";
import imgNoticia2 from "../assets/images/news/Noticia_2.png";
import imgNoticia3 from "../assets/images/news/Noticia_3.png";
import userNew from "../assets/images/news/user-new.svg";
import byFoliaTag from "../assets/images/news/by-folia-tag.svg";
import imgIcon from "../assets/images/icons/img-icon.svg";
import needsRectangle1 from "../assets/images/needs/rectangle1.png";
import needsRectangle2 from "../assets/images/needs/rectangle2.png";
import needsGeoIteligence from "../assets/images/needs/Logo_Folia_GeoIteligence.svg";
import needsPlatform from "../assets/images/needs/Logo_Folia_Platform.svg";
import needsReport from "../assets/images/needs/Logo_Folia_Report.svg";
import logoFoliaFooter from "../assets/images/footer/logo_folia.svg";
import logoMediumFooter from "../assets/images/footer/medium.svg";
import logoLinkedinFooter from "../assets/images/footer/linkedin.svg";
import logoXFooter from "../assets/images/footer/x.svg";
import logoApoyosFooter from "../assets/images/footer/logos_apoyos.svg";
import evidenciaComo from "../assets/images/experience/evidencia_como_lo_hacemos.png";
import evaluamosComo from "../assets/images/experience/evaluamos_como_lo_hacemos.png";
import datosComo from "../assets/images/experience/datos_como_lo_hacemos.png";
import logoHorizontalDreamgis from "../assets/images/footer/logo-horizontal-dreamgis.png";
import logoWhiteDreamgis from "../assets/images/footer/Logo_dreamgis_blanco.svg";
import userCarlos from "../assets/images/news/image-carlos.jpg";
import testimonyLaura from "../assets/images/testimony/testimony-laura.jpg";
import debidaDiligenciaIcon from "../assets/images/icons/debida_diligencia.png";
import informacionCadenaIcon from "../assets/images/icons/informacion_cadena.png";
import mantenimientoIcon from "../assets/images/icons/mantenimiento.png";
import temporalidadIcon from "../assets/images/icons/temporalidad.png";
import materiaPrimaIcon from "../assets/images/icons/materia_prima.png";
import foliaTrace from "../assets/images/needs/folia_trace.svg";
import foliaTraceImage from "../assets/images/needs/folia_trace_image.png";
import foliaRoute from "../assets/images/needs/folia_route.svg";
import foliaRouteImage from "../assets/images/needs/folia_route_image.png";
import foliaImpact from "../assets/images/needs/folia_impact.svg";
import foliaImpactImage from "../assets/images/needs/folia_impact_image.png";
import foliaReport from "../assets/images/needs/folia_report.svg";
import foliaReportImage from "../assets/images/needs/folia_report_image.png";

const Home = () => {
    const [selectedTabExperience, setSelectedTabExperience] = useState('Nuestro');
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [email, setEmail] = useState("")
    const [menuActive, setMenuActive] = useState("Home")
    const [password, setPassword] = useState("")
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [messageErrorModal, setMessageErrorModal] = useState(false);
    const { authenticate, passwordUser } = useContext(AccountContext);
    const navigate = useNavigate();

    const handleCloseLoginModal = () => setShowLoginModal(false);
    const handleOpenLoginModal = () => setShowLoginModal(true);

    useEffect(() => {
        const bannerFondo = document.getElementById("bannerFondo");
        if (bannerFondo) bannerFondo.play();
    }, []);

    const handleTabChange = (tab) => {
        setMenuActive(tab)
        document.getElementById(tab + '-cont').scrollIntoView();

        Array.from(document.querySelectorAll('.btn-side-left-home')).forEach(
            (el) => el.classList.remove('btn-side-left-home-selected')
        );

        document.getElementById('home' + tab + 'BTN').classList.add("btn-side-left-home-selected")
    };

    const handleTabExperience = (item) => {
        Array.from(document.querySelectorAll('.home-experience-title')).forEach(
            (el) => el.classList.remove('home-experience-title-selected')
        );

        document.getElementById('homeExpTitle' + item).classList.add("home-experience-title-selected")

        setSelectedTabExperience(item)
    };

    const onSubmit = (e) => {
        e.preventDefault();
        authenticate(email, password)
            .then(data => {
                /*if(data.newPass !== undefined){
                    // Redirigir a ChangePassword
                    navigate("/PureMap", { state: { data } });
                }*/
                localStorage.setItem('myData', data);
                navigate("/PureMap", { state: { data } });
            })
            .catch(err => {
                const errorMessage = err.message || err;
                if (errorMessage.includes('Incorrect username')) {
                    setShowErrorModal(true);
                    setMessageErrorModal(' Usuario y/o contraseña incorrectos. Por favor, inténtalo de nuevo.')
                }
                if (errorMessage.includes('User does not exist')) {
                    setShowErrorModal(true);
                    setMessageErrorModal('El usuario no existe. Por favor, pongase en contacto con los administradores del sistema.')
                }
            })
    }

    const toggleShowPassword = () => {
        const passwordInput = document.getElementById("password");
        const togglePassword = document.getElementById("togglePassword");

        if (passwordInput.type === "password") {
            passwordInput.type = "text";
            togglePassword.classList.remove("fa-eye-slash");
            togglePassword.classList.add("fa-eye");
        } else {
            passwordInput.type = "password";
            togglePassword.classList.remove("fa-eye");
            togglePassword.classList.add("fa-eye-slash");
        }
    }

    function handleMail() {
        window.open('mailto:folia@dreamgis.com');
    }

    const handleCloseErrorModal = () => setShowErrorModal(false);

    return (
        <div className="conts-dash-home d-flex flex-column">
            <Sidebar handleOpenLoginModal={handleOpenLoginModal} />

            <div className="cont-home">
                <div className="row">
                    <div className="cont-btn-side-left-home col-1 ms-5">
                        <button id="homeHomeBTN" className='btn-side-left-home btn-side-left-home-selected'
                            onClick={() => handleTabChange('Home')}>
                            {menuActive === 'Home' && (<img src={iconPlus} alt="Menú activo" />)}
                            <span className='folia-font-s folia-font-s-regular'>Inicio</span>
                        </button>
                        <button id="homeNewsBTN" className='btn-side-left-home'
                            onClick={() => handleTabChange('News')}>
                            {menuActive === 'News' && (<img src={iconPlus} alt="Menú activo" />)}
                            <span className='folia-font-s folia-font-s-regular'>Noticias</span>
                        </button>
                        <button id="homeExperienceBTN" className='btn-side-left-home'
                            onClick={() => handleTabChange('Experience')}>
                            {menuActive === 'Experience' && (<img src={iconPlus} alt="Menú activo" />)}
                            <span className='folia-font-s folia-font-s-regular'>Experiencia</span>
                        </button>
                        <button id="homeRegulationBTN" className='btn-side-left-home'
                            onClick={() => handleTabChange('Regulation')}>
                            {menuActive === 'Regulation' && (<img src={iconPlus} alt="Menú activo" />)}
                            <span className='folia-font-s folia-font-s-regular'>Regulación</span>
                        </button>
                        <button id="homeSolutionsBTN" className='btn-side-left-home'
                            onClick={() => handleTabChange('Solutions')}>
                            {menuActive === 'Solutions' && (<img src={iconPlus} alt="Menú activo" />)}
                            <span className='folia-font-s folia-font-s-regular'>Servicios</span>
                        </button>
                        <button id="homeContactUsBTN" className='btn-side-left-home'
                            onClick={() => handleTabChange('ContactUs')}>
                            {menuActive === 'ContactUs' && (<img src={iconPlus} alt="Menú activo" />)}
                            <span className='folia-font-s folia-font-s-regular'>Contáctanos</span>
                        </button>
                    </div>

                    <div id='contPanels' className="col-12 cont-panels px-0">
                        {/* {selectedTab === 'Home' && ( */}
                        <div id='Home-cont' className="pb-5 home-cont">
                            <video autoplay muted loop id="bannerFondo">
                                <source src={bannerFondo} type="video/mp4" />
                                Su navegador no soporta vídeo HTML5.
                            </video>
                            <div className='cont-home-cont'>
                                <div className='top-header-white'></div>
                                <div className='home-home-cont col-7 offset-2 pt-5'>
                                    <h1 className='home-home-title folia-font folia-font-xxl-bold'>Cadenas Libres de deforestación y cumplimiento EUDR</h1>
                                    <p className='mt-5 folia-font folia-font-l folia-font-l-regular-small'>
                                        Desarrollamos soluciones a la medida para que  múltiples sectores productivos
                                        <span className='folia-font-l-bold-small'> analicen su riesgo de deforestación </span>
                                        asegurando el cumplimiento de políticas y regulaciones enfocada a cadenas libres de deforestación
                                    </p>
                                    <div className='home-home-cont-btns mt-5'>
                                        <button className='folia-font folia-font-s folia-font-s-regular-tracking
                                    folia-button folia-button-loud-blue' onClick={handleMail}>Hablar con un experto</button>
                                        <button className='folia-font folia-font-s folia-font-s-regular-tracking
                                    folia-button folia-button-quiet-dark ms-4' onClick={handleMail}>Cotizar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* )}
                        {selectedTab === 'News' && ( */}
                        <div id='News-cont'>
                            <div className="home-news-cont col-10 offset-2 py-5 pe-5 home-cont">
                                <h1 className='folia-font folia-font-xl folia-font-xl-light'>Noticias del sector</h1>
                                <div className='row mt-4'>
                                    <div className='col-4'>
                                        <a href="https://www.frontiertechhub.org/insights/call7-pioneer-story-pedro-castro" target="_blank" rel="noopener noreferrer">
                                        <div className='home-news-cont-card'>
                                            <div className='home-news-card-header position-relative'>
                                                <img src={imgNoticia1} alt="Imagen Noticia" />
                                                <div className='home-news-card-tag card-tag-green folia-font folia-font-xs folia-font-xs-medium'>DEFORESTACIÓN</div>
                                            </div>
                                            <div className='home-news-card-body'>
                                                <div className='home-news-card-body-s1'>
                                                    <span className='date-news folia-font folia-font-xs folia-font-xs-semibold-tracking'>02 - FEB - 24</span>
                                                </div>
                                                <div className='home-news-card-body-s2 mt-2'>
                                                        <p className='folia-font folia-font-m folia-font-m-semibold mb-0'>
                                                        ¿Con los datos adecuados, podrían los agricultores remotos de cacao en Colombia acceder a los mercados
                                                        internacionales y abrir camino a una nueva forma de proteger los bosques tropicales del país?
                                                        </p>
                                                </div>
                                                <div className='home-news-card-body-s3'>
                                                    <p className='p-new-description folia-font folia-font-s folia-font-s-regular mb-0'>
                                                        La coca, la hoja necesaria para producir la droga ilegal cocaína, se puede cultivar fácilmente en la
                                                        mayoría de las regiones de Colombia. Cultivos como el maíz, la soja y ...
                                                    </p>
                                                </div>
                                                <div className='home-news-card-body-s4'>
                                                    <img src={userCarlos} alt="Redactor" />
                                                    <div>
                                                        <span className='folia-font folia-font-xs folia-font-xs-medium d-block'>Carlos Pedraza</span>
                                                        <span className='author-new folia-font folia-font-xs folia-font-xs-medium-italic d-block'>Director DREAMGIS</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></a>
                                    </div>
                                    <div className='col-4'>
                                        <a href="https://www.frontiertechhub.org/insights/sprint-2-cocoa-value-chains" target="_blank" rel="noopener noreferrer">
                                        <div className='home-news-cont-card'>
                                            <div className='home-news-card-header position-relative'>
                                                <img src={imgNoticia2} alt="Imagen Noticia" />
                                                <div className='home-news-card-tag card-tag-orange folia-font folia-font-xs folia-font-xs-medium'>POLÍTICA</div>
                                            </div>
                                            <div className='home-news-card-body'>
                                                <div className='home-news-card-body-s1'>
                                                    <span className='date-news folia-font folia-font-xs folia-font-xs-semibold-tracking'>02 - FEB - 24</span>
                                                    {/* <div className='home-news-card-tag'>Tag por tema</div> */}
                                                </div>
                                                <div className='home-news-card-body-s2 mt-2'>
                                                    <p className='folia-font folia-font-m folia-font-m-semibold mb-0'>
                                                        Desafíos para el desarrollo de sistemas robustos para el cumplimiento de la regulación EUDR.
                                                    </p>
                                                </div>
                                                <div className='home-news-card-body-s3'>
                                                    <p className='p-new-description folia-font folia-font-s folia-font-s-regular mb-0'>
                                                        Hemos generado aprendizajes clave sobre cómo utilizar nuestras tecnologías piloto (aplicaciones de campo,
                                                        datos de observación de la Tierra y blockchain) para crear trazabilidad y cumplir con los requisitos de
                                                        debida diligencia en la cadena de valor del...
                                                    </p>
                                                </div>
                                                <div className='home-news-card-body-s4'>
                                                    <img src={userCarlos} alt="Redactor" />
                                                    <div>
                                                        <span className='folia-font folia-font-xs folia-font-xs-medium d-block'>Carlos Pedraza</span>
                                                        <span className='author-new folia-font folia-font-xs folia-font-xs-medium-italic d-block'>Director DREAMGIS</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></a>
                                    </div>
                                    <div className='col-4'>
                                        <a href="https://www.frontiertechhub.org/insights/cocoa-sprint3" target="_blank" rel="noopener noreferrer">
                                        <div className='home-news-cont-card'>
                                            <div className='home-news-card-header position-relative'>
                                                <img src={imgNoticia3} alt="Imagen Noticia" />
                                                <div className='home-news-card-tag card-tag-blue folia-font folia-font-xs folia-font-xs-medium'>TECNOLOGÍA</div>
                                                <img className='home-news-by-folia' src={byFoliaTag} alt="By Folia" />
                                            </div>
                                            <div className='home-news-card-body'>
                                                <div className='home-news-card-body-s1'>
                                                    <span className='date-news folia-font folia-font-xs folia-font-xs-semibold-tracking'>02 - FEB - 24</span>
                                                    {/* <div className='home-news-card-tag'>Tag por tema</div> */}
                                                </div>
                                                <div className='home-news-card-body-s2'>
                                                    <p className='folia-font folia-font-m folia-font-m-semibold mb-0'>
                                                        FOLIA: Una Solución Basada en la Nube para Monitorear la Deforestación y Garantizar el Cumplimiento de la regulación EUDR.
                                                    </p>
                                                </div>
                                                <div className='home-news-card-body-s3'>
                                                    <p className='p-new-description folia-font folia-font-s folia-font-s-regular mb-0'>
                                                        En una era en la que la sostenibilidad, los negocios éticos y el cumplimiento normativo dominan el discurso global,
                                                        las empresas buscan continuamente soluciones de vanguardia para satisfacer estas ....
                                                    </p>
                                                </div>
                                                <div className='home-news-card-body-s4'>
                                                    <img src={userCarlos} alt="Redactor" />
                                                    <div>
                                                        <span className='folia-font folia-font-xs folia-font-xs-medium d-block'>Carlos Pedraza</span>
                                                        <span className='author-new folia-font folia-font-xs folia-font-xs-medium-italic d-block'>Director DREAMGIS</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></a>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center mt-4'>
                                    <a href="https://www.frontiertechhub.org/insights" target="_blank" rel="noopener noreferrer"><button className='folia-button folia-button-loud-blue'>VER MÁS NOTICIAS</button></a>
                                </div>
                            </div>
                        </div>
                        {/* )}
                        {selectedTab === 'Experience' && ( */}
                        <div id='Experience-cont'>
                            <div className="home-experience-cont col-10 offset-2 py-5 pe-5 home-cont">
                                <div className='row'>
                                    <div className='col-4 mb-4 d-flex'>
                                        {selectedTabExperience === 'Nuestro' && (<img src={iconPlus} className='me-2' alt="Menú activo experiencia" />)}
                                        <h3 id="homeExpTitleNuestro" className='home-experience-title-selected mb-0'>
                                            <a className={selectedTabExperience === 'Nuestro' ?
                                                'home-experience-title-a folia-font folia-font-l folia-font-l-bold' :
                                                'folia-font folia-font-l folia-font-l-bold'}
                                                onClick={() => handleTabExperience('Nuestro')}>Nuestro trabajo</a>
                                        </h3>
                                    </div>
                                    <div className='col-4 mb-4 d-flex'>
                                        {selectedTabExperience === 'Como' && (<img src={iconPlus} className='me-2' alt="Menú activo experiencia" />)}
                                        <h3 id="homeExpTitleComo" className='mb-0'>
                                            <a className={selectedTabExperience === 'Como' ?
                                                'home-experience-title-a folia-font folia-font-l folia-font-l-bold' :
                                                'folia-font folia-font-l folia-font-l-bold'}
                                                onClick={() => handleTabExperience('Como')}>Cómo lo hacemos</a>
                                        </h3>
                                    </div>
                                    <div className='col-4 mb-4 d-flex'>
                                        {selectedTabExperience === 'Testimonios' && (<img src={iconPlus} className='me-2' alt="Menú activo experiencia" />)}
                                        <h3 id="homeExpTitleTestimonios" className='mb-0'>
                                            <a className={selectedTabExperience === 'Testimonios' ?
                                                'home-experience-title-a folia-font folia-font-l folia-font-l-bold' :
                                                'folia-font folia-font-l folia-font-l-bold'}
                                                onClick={() => handleTabExperience('Testimonios')}>Multimedia</a>
                                        </h3>
                                    </div>
                                </div>
                                {selectedTabExperience === 'Nuestro' && (
                                    <>
                                        <div className='row mt-5'>
                                            <div className='col-4'>
                                                <div className='home-experience-cont-card'>
                                                    <h5 className='folia-font folia-font-m folia-font-m-semibold-tracking'>PREDIOS ANALIZADOS</h5>
                                                    <div className='py-3'>
                                                        <span className='folia-font folia-font-xl folia-font-xl-bold'>+2.186</span>
                                                    </div>
                                                    <p className='folia-font folia-font-s folia-font-s-regular mb-0'>
                                                        Monitoreado acuerdos de cero deforestación y procesos de restauración de bosques
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className='home-experience-cont-card'>
                                                    <h5 className='folia-font folia-font-m folia-font-m-semibold-tracking'>TECNOLOGÍA</h5>
                                                    <div className='py-3'>
                                                        <img src={imgIcon} alt="Icono" />
                                                        <img className='ms-5' src={imgIcon} alt="Icono" />
                                                    </div>
                                                    <p className='folia-font folia-font-s folia-font-s-regular mb-0'>
                                                        Integramos analítica avanzada y procesamiento en la nube para ofrecer servicios eficientes
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-4'>
                                            <div className='col-4'>
                                                <div className='home-experience-cont-card'>
                                                    <h5 className='folia-font folia-font-m folia-font-m-semibold-tracking'>MONITOREO GLOBAL</h5>
                                                    <div className='py-3'>
                                                        <span className='folia-font folia-font-xl folia-font-xl-bold'>6 países</span>
                                                    </div>
                                                    <div className='row folia-font folia-font-s folia-font-s-regular mb-0 text-start'>
                                                        <div className='col-6'>
                                                            <ul>
                                                                <li>Canada</li>
                                                                <li>Estados Unidos</li>
                                                                <li>México</li>
                                                            </ul>
                                                        </div>
                                                        <div className='col-6'>
                                                            <ul>
                                                                <li>Colombia</li>
                                                                <li>Ecuador</li>
                                                                <li>Perú</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><div className='col-4'>
                                                <div className='home-experience-cont-card'>
                                                    <h5 className='folia-font folia-font-m folia-font-m-semibold-tracking'>EXPERIENCIA Y CALIDAD</h5>
                                                    <div className='py-3'>
                                                        <span className='folia-font folia-font-xl folia-font-xl-bold'>+10 años</span>
                                                    </div>
                                                    <p className='folia-font folia-font-s folia-font-s-regular mb-0'>
                                                        Nuestro compromiso es el de entregar evidencias con la más alta calidad soportada por
                                                        años de experiencia de nuestros analistas
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className='home-experience-cont-card d-flex justify-content-center'>
                                                    <div className='row d-flex justify-content-center'>
                                                        <div className='col-6'>
                                                            <div className='home-experience-card-header'>
                                                                <h5 className='folia-font folia-font-m folia-font-m-semibold-tracking'>SECTORES PRODUCTIVOS</h5>
                                                            </div>
                                                            <div className='home-experience-card-body'>
                                                                <span className='folia-font folia-font-xl folia-font-xl-bold'>7</span>
                                                            </div>
                                                            <div className='home-experience-card-footer'>
                                                                <p className='folia-font folia-font-s folia-font-s-regular mb-0'>
                                                                    Con los que trabajamos
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 folia-font folia-font-s folia-font-s-regular mb-0 text-start'>
                                                            <ul className='m-0'>
                                                                <li>Cacao</li>
                                                                <li>Ganadería</li>
                                                                <li>Fondos de agua</li>
                                                                <li>Café</li>
                                                                <li>Palma de aceite</li>
                                                                <li>Créditos agropecuarios</li>
                                                                <li>Turismo de naturaleza</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {selectedTabExperience === 'Como' && (
                                    <div className='row mt-4'>
                                        <div className='col-3 mx-3'>
                                            <div className='home-news-cont-card'>
                                                <div className='home-news-card-body-how'>
                                                    <div className='home-experience-card-body-s1 d-flex'>
                                                        <img className='me-4' src={imgIcon} alt="Icono" />
                                                        <strong className='folia-font folia-font-l folia-font-l-bold-small'>Mapeamos con Precisión</strong>
                                                    </div>
                                                    <img className='my-0' src={datosComo} alt="Icono" />
                                                    <div className='home-news-card-body-s2 mt-2'>
                                                        <ul>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Datos y app móvil:</b> El cliente proporciona coordenadas o usa nuestra app.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Monitoreo satelital:</b> Observamos bosques y cultivos globalmente.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Datos oficiales:</b> Integramos información legal como áreas protegidas, resguardos indigenas, frontera agrícola.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Mapeo de actores:</b> Identificamos actores y flujos clave.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Conexiones trazadas:</b> Trazamos conexiones entre proveedores y consumidores.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3 mx-3'>
                                            <div className='home-news-cont-card'>
                                                <div className='home-news-card-body-how'>
                                                    <div className='home-experience-card-body-s1 d-flex'>
                                                        <img className='me-4' src={imgIcon} alt="Icono" />
                                                        <strong className='folia-font folia-font-l folia-font-l-bold-small'>Evaluamos el riesgo</strong>
                                                    </div>
                                                    <img className='my-0' src={evaluamosComo} alt="Icono" />
                                                    <div className='home-news-card-body-s2 mt-2'>
                                                        <ul>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Analítica avanzada:</b> Usamos Machine Learning para evaluar riesgos con alta exactitud</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Análisis legal:</b> Evaluamos fincas en áreas con restricciones agropecuarias.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Criterios precisos:</b> Establecemos políticas de cero deforestación basadas en datos.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Interacciones y dependencias:</b> Analizamos la cadena de suministro para anticipar impactos.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Integridad de datos:</b> Aseguramos consistencia de datos desde el origen al consumidor final.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3 mx-3'>
                                            <div className='home-news-cont-card'>
                                                <div className='home-news-card-body-how'>
                                                    <div className='home-experience-card-body-s1 d-flex'>
                                                        <img className='me-4' src={imgIcon} alt="Icono" />
                                                        <strong className='folia-font folia-font-l folia-font-l-bold-small'>Evidencias para la acción</strong>
                                                    </div>
                                                    <img className='my-0' src={evidenciaComo} alt="Icono" />
                                                    <div className='home-news-card-body-s2 mt-2'>
                                                        <ul>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Documentación y trazabilidad:</b> Documentamos y trazamos todo el análisis de riesgo.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Acceso a la plataforma:</b> Los clientes pueden usar nuestra plataforma o integrarla.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Seguridad en la nube:</b> Almacenamos datos y evidencias con alta seguridad.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Trazabilidad completa:</b> Proveemos trazabilidad del producto, mejorando la transparencia.</li>
                                                            <li className='folia-font folia-font-s folia-font-s-regular mb-3'>
                                                                <b>Dashboards interactivos:</b> Ofrecemos visualizaciones en tiempo real para detectar riesgos.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {selectedTabExperience === 'Testimonios' && (
                                    <div>
                                        <div className='row'>
                                            {/*<div className='col-11 cont-tab-testimonios'>
                                                <p>
                                                    <div>
                                                        <img src={testimonyLaura} alt="Redactor" />
                                                        <span className='folia-font folia-font-s home-contactus-text-lignt mb-0'>Laura Romero</span>
                                                        <span className='testimony-new folia-font folia-font-xs folia-font-xs-medium-italic d-block'>Socia Fundadora de Awake y Niddo. Directora de Producto en Awake</span>
                                                    </div>
                                                </p>
                                                <p className='folia-font folia-font-s
                                                    home-contactus-text-lignt mb-0'>
                                                    Trabajar con Folía ha sido muy potente para Awake, hemos logrado verificar eficientemente las acciones de conservación y restauración de nuestra red, generando lazos de confianza entre viajeros y anfitriones locales soportados por datos y acciones concretas
                                                </p>
                                            </div>*/}
                                            <div className='video-container'>
                                                <iframe src="https://player.vimeo.com/video/923143159?h=e706321f61" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                            <div className='col-1 cont-next-testimonio'>
                                                →
                                            </div>
                                        </div>
                                        {/*<h4 className='mt-4 mb-4'><strong>Empresas que confían en nuestro trabajo</strong></h4>
                                        <div className='cont-logos-testimonio mt-3'>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                        </div>
                                        <div className='cont-logos-testimonio mt-3'>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                            <div className='cont-logo-testimonio'>Logo</div>
                                        </div>*/}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* )}
                        {selectedTab === 'Regulation' && ( */}
                        <div id='Regulation-cont'>
                            <div className="home-regulation-cont col-9 offset-2 py-5 pe-5 home-cont">
                                <h1 className='col-6 folia-font folia-font-xl folia-font-xl-light'>Puntos claves de la regulación EUDR</h1>
                                <div className='row mt-5'>
                                    <div className='col-regulation'>
                                        <div className='cont-icons-regulation mb-3'>
                                            <img src={debidaDiligenciaIcon} alt="Icono" />
                                        </div>
                                        <strong className='d-block folia-font folia-font-l folia-font-l-bold-small mb-3'>Debida Diligencia</strong>
                                        <span className='folia-font folia-font-s folia-font-s-regular'>
                                            Operadores y comerciantes deben demostrar que las materias primas han sido producidas en tierras
                                            que no han sido objeto de deforestación después del 31 de diciembre 2023
                                        </span>
                                    </div>
                                    <div className='col-regulation'>
                                        <div className='cont-icons-regulation mb-3'>
                                            <img src={informacionCadenaIcon} alt="Icono" />
                                        </div>
                                        <strong className='d-block folia-font folia-font-l folia-font-l-bold-small mb-3'>Información cadena suministro</strong>
                                        <span className='folia-font folia-font-s folia-font-s-regular'>
                                            Las coordenadas (fincas &lt; 4 has) y polígonos prediales (fincas &gt; 4 has) deben ser analizadas para evaluar
                                            el riesgo de deforestación a partir del 31 diciembre 2020
                                        </span>
                                    </div>
                                    <div className='col-regulation'>
                                        <div className='cont-icons-regulation mb-3'>
                                            <img src={mantenimientoIcon} alt="Icono" />
                                        </div>
                                        <strong className='d-block folia-font folia-font-l folia-font-l-bold-small mb-3'>Mantenimiento de registros</strong>
                                        <span className='folia-font folia-font-s folia-font-s-regular'>
                                            Se adjuntan evidencias e información concluyente y verificable de que las fincas de las que
                                            provienen los productos cumplen con la cero deforestación
                                        </span>
                                    </div>
                                    <div className='col-regulation'>
                                        <div className='cont-icons-regulation mb-3'>
                                            <img src={temporalidadIcon} alt="Icono" />
                                        </div>
                                        <strong className='d-block folia-font folia-font-l folia-font-l-bold-small mb-3'>Temporalidad</strong>
                                        <span className='folia-font folia-font-s folia-font-s-regular'>
                                            Realizar la evaluación del riesgo de deforestación anualmente para la cadena de suministro
                                        </span>
                                    </div>
                                    <div className='col-regulation'>
                                        <div className='cont-icons-regulation mb-3'>
                                            <img src={materiaPrimaIcon} alt="Icono" />
                                        </div>
                                        <strong className='d-block folia-font folia-font-l folia-font-l-bold-small mb-3'>Materias primas</strong>
                                        <span className='folia-font folia-font-s folia-font-s-regular'>
                                            Siete materias primas para las que aplica la regulación: ganado, cacao, café, palma aceitera,
                                            caucho, soja y madera, así como productos derivados como chocolate, cuero o muebles.
                                        </span>
                                    </div>
                                </div>
                                <div className='my-5 d-flex justify-content-center'>
                                    {/*<button className='folia-button folia-button-loud-blue'>VER REGULACIÓN</button>*/}
                                </div>
                            </div>
                        </div>
                        {/* )}
                        {selectedTab === 'Solutions' && ( */}
                        <div id='Solutions-cont'>
                            <div className="home-solutions-cont col-10 offset-2 py-5 pe-5 home-cont">
                                <h1 className='col-12 folia-font folia-font-xl folia-font-xl-light'>Nos adaptamos a sus necesidades</h1>
                                <div className='row mt-4'>                                    
                                    <div className='col-3'>
                                        <div className='home-news-cont-card'>
                                            <div className='home-solutions-card-header'></div>
                                            <div className='home-solutions-card-picture'>
                                                <img className='home-solutions-card-logo' src={foliaTrace} alt="Icono" /><br></br>
                                                <img src={foliaTraceImage} alt="Icono" />
                                            </div>
                                            <div className='home-solutions-card-body'>
                                                <div className='home-solutions-card-body-s2 mt-2'>
                                                    <ul>
                                                        <li className='mt-3'><strong>Mapeo y diagnóstico</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Identificamos actores y flujos en tu cadena de suministro.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Trazabilidad completa</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Aseguramos el origen con herramientas avanzadas.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Evaluación de riesgos</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Priorizamos proveedores según el riesgo de deforestación y figuras legales.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Prevención de riesgos</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Detectamos puntos críticos para reducir riesgos legales y de deforestación.</li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                                <div className='home-solutions-card-body-s3 mt-2'>
                                                    <button className='folia-button folia-button-quiet-dark' onClick={handleMail}>AGENDAR DEMO</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='home-news-cont-card'>
                                            <div className='home-solutions-card-header'></div>
                                            <div className='home-solutions-card-picture'>
                                                <img className='home-solutions-card-logo' src={foliaRoute} alt="Icono" /><br></br>
                                                <img src={foliaRouteImage} alt="Icono" />
                                            </div>
                                            <div className='home-solutions-card-body'>
                                                <div className='home-solutions-card-body-s2 mt-2'>
                                                    <ul>
                                                        <li className='mt-3'><strong>Política cero deforestación</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Creamos políticas empresariales claras.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Visión y principios</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Establecemos una visión y principios sólidos.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Hoja de ruta</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Definimos un plan de acción con hitos y responsables.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Reputación y normativas</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Blindamos de riesgos legales y reputacionales asociados a la deforestación.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Relaciones estratégicas</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Mejoramos la eficiencia y reducimos riesgos con proveedores.</li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                                <div className='home-solutions-card-body-s3 mt-2'>
                                                    <button className='folia-button folia-button-quiet-dark' onClick={handleMail}>OBTENER REPORTE</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='home-news-cont-card'>
                                            <div className='home-solutions-card-header card-header-reco'></div>
                                            <div className='home-solutions-card-picture home-solutions-card-picture-reco'>
                                                <img className='home-solutions-card-logo' src={foliaReport} alt="Icono" /><br></br>
                                                <img src={foliaReportImage} alt="Icono" />
                                            </div>
                                            <div className='home-solutions-card-body card-body-reco'>
                                                <div className='home-solutions-card-body-s2 mt-2'>
                                                    <ul>
                                                        <li className='mt-3'><strong>Evaluación de deforestación</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Integramos décadas de experiencia, satélites de última generación y analítica avanzada.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Cumplimiento normativo</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Aseguramos el cumplimiento con regulaciones como EUDR.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Plataformas a la medida</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Gestiona fácilmente el riesgo,  reportes y evidencias de forma trazable.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Alta exactitud</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Proveemos análisis con la mayor exactitud del mercado (&gt; 95%).</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Soluciones integradas</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Ofrecemos soluciones que se integran fácilmente.</li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                                <div className='home-solutions-card-body-s3 mt-2'>
                                                    <button className='folia-button folia-button-loud-blue' onClick={handleMail}>OBTENER PLAN</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='home-news-cont-card'>
                                            <div className='home-solutions-card-header'></div>
                                            <div className='home-solutions-card-picture'>
                                                <img className='home-solutions-card-logo' src={foliaImpact} alt="Icono" /><br></br>
                                                <img src={foliaImpactImage} alt="Icono" />
                                            </div>
                                            <div className='home-solutions-card-body'>
                                                <div className='home-solutions-card-body-s2 mt-2'>
                                                    <ul>
                                                        <li className='mt-3'><strong>Evaluación de restauración</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Integramos herramientas GIS y monitoreo satelital.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Área importantes para la conectividad de la biodiversidad</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Apoyamos con datos la planeación y medición de impacto de implementaciones.</li>
                                                        </ul>
                                                        <li className='mt-3'><strong>Decisiones informadas</strong></li>
                                                        <ul>
                                                            <li><span class="green-check"></span>Proveemos datos para decisiones estratégicas.</li>
                                                        </ul>
                                                        
                                                    </ul>
                                                </div>
                                                <div className='home-solutions-card-body-s3 mt-2'>
                                                    <button className='folia-button folia-button-quiet-dark' onClick={handleMail}>COTIZAR SOLUCIÓN</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* )}
                        {selectedTab === 'ContactUs' && ( */}
                        <div id='ContactUs-cont'>
                            <div className="home-contactus-cont col-10 offset-2 py-5 pe-5 home-cont">
                                <h1 className='col-6 folia-font folia-font-xl folia-font-xl-light'>Estamos a un click de distancia</h1>
                                <div className='row mt-5'>
                                    <div className='col-6'>
                                        <div className='home-contactus-cont-card'>
                                            <div className='home-contactus-card-header'>
                                                <h4 className='folia-font folia-font-l folia-font-l-bold mb-0'>
                                                    Realiza una cotización rápida a través de nuestro canal de atención
                                                </h4>
                                            </div>
                                            <div className='home-contactus-card-body mt-4'>
                                                <p className='folia-font folia-font-s mb-0'>
                                                    Desarrollamos soluciones técnica y financieramente a la medida.
                                                    Por lo que te invitamos a comunicarte con nosotros para asistirte en el proceso
                                                </p>
                                            </div>
                                            <div className='home-contactus-card-footer mt-4'>
                                                <button className='folia-button folia-button-loud-blue' onClick={handleMail}>COTIZAR</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='home-contactus-cont-card home-contactus-cont-card-lignt'>
                                            <div className='home-contactus-card-header'>
                                                <h4 className='folia-font folia-font-l folia-font-l-bold
                                                    home-contactus-text-lignt mb-0'>Realiza una demo gratis</h4>
                                            </div>
                                            <div className='home-contactus-card-body mt-4'>
                                                <p className='folia-font folia-font-s
                                                    home-contactus-text-lignt mb-0'>
                                                    Conéctate con nuestros especialistas y conoce más en detalle como FOLIA puede ayudar a tu cadena.
                                                </p>
                                            </div>
                                            <div className='home-contactus-card-footer mt-4'>
                                                <button className='folia-button folia-button-loud-blue' onClick={handleMail}>AGENDAR DEMO</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                </div>
                <div id='footer-home' className='pt-5 mb-4'>
                    <div className='row p-0 m-0 ms-5'>
                        <div className='col-footer'>
                            <img src={logoFoliaFooter} alt="Icono" />
                        </div>
                        <div className='col-footer'>
                            <span className='d-block folia-font folia-font-s folia-font-s-regular-tracking title-footer'>SOBRE FOLIA</span>
                            <span className='d-block folia-font folia-font-s folia-font-s-regular-tracking'>EXPERIENCIA</span>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium my-3'>Nuestro trabajo</span>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium my-3'>Cómo lo hacemos</span>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium my-3'>Multimedia</span>
                        </div>
                        <div className='col-footer'>
                            <span className='d-block folia-font folia-font-s folia-font-s-regular-tracking title-footer'>REGULACIÓN</span>
                            <span className='d-block folia-font folia-font-s folia-font-s-regular-tracking'>SERVICIOS</span>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium my-3'>Folia Report</span>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium my-3'>Folia platform</span>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium my-3'>Folia Geo-intelligence</span>
                        </div>
                        <div className='col-footer'>
                            <span className='d-block folia-font folia-font-s folia-font-s-regular-tracking'>NOTICIAS</span>
                        </div>
                        <div className='col-footer'>
                            <span className='d-block folia-font folia-font-s folia-font-s-regular-tracking'>CONTÁCTENOS</span>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium my-3'>contacto@folia.com</span>
                        </div>
                        <div className='col-footer'></div>
                        <div className='col-footer'>
                            <span className='d-block folia-font folia-font-s folia-font-s-regular-tracking'>REDES SOCIALES</span>
                            <div className='cont-social-icons my-3'>
                                <img src={logoMediumFooter} alt="Icono" />
                                <img src={logoLinkedinFooter} alt="Icono" />
                                <img src={logoXFooter} alt="Icono" />
                            </div>
                        </div>
                        <div className='col-footer'></div>
                    </div>
                    <div className='row p-0 m-0 ms-5 mt-5'>
                        <div className='col-8'>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium txt-sub-footer'>
                                Powered by
                            </span>
                            <img src={logoWhiteDreamgis} alt="Logo Powered by DreamGIS" className="logo-powered-by" />
                        </div>
                        <div className='col-4'>
                            <span className='d-block folia-font folia-font-xs folia-font-xs-medium txt-sub-footer mb-3'>
                                Con el apoyo de:
                            </span>
                            <img src={logoApoyosFooter} alt="Logo Apoyos" className="logo-support" />
                        </div>
                    </div>
                    <div className='row p-0 m-0 ms-5'>
                        <span className='d-block folia-font folia-font-xs folia-font-xs-medium txt-sub-footer'>
                            Copyright © 2024 Folia Forest Monitoring. All rights reserved.
                        </span>
                    </div>
                </div>
            </div>
            <div className="conts-dash-home d-flex flex-column">
                {/* Resto del contenido */}
                <Modal show={showLoginModal} onHide={handleCloseLoginModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Inicio de sesión</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="form-card" onSubmit={onSubmit}>
                            <div className="row justify-content-between text-left">
                                <div className="form-group col-md-12 flex-column d-flex">
                                    <label className="form-control-label px-3" htmlFor="email">Correo<span className="text-danger"> *</span></label>
                                    <input type="email" id="email" name="email" placeholder="micorreo@mail.com"
                                        className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="row justify-content-between text-left">
                                <div className="form-group col-md-12 flex-column d-flex">
                                    <label className="form-control-label px-3" htmlFor="password">Contraseña<span className="text-danger"> *</span></label>
                                    <div className="input-group" id="show_hide_password">
                                        <input type="password" id="password" name="password" placeholder=""
                                            className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <div id="contTogglePassword" className="input-group-addon">
                                            <i id="togglePassword" className="fa fa-eye-slash" aria-hidden="true" onClick={toggleShowPassword}></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="text-center mt-4">
                                    <button type="submit" className="btn btn-success custom-button">Ingresar</button>
                                </div>
                            </div>
                        </form>
                        {/* Modal de error */}
                        <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Error de autenticación</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {messageErrorModal}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseErrorModal}>
                                    Cerrar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseLoginModal}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Home;
