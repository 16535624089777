import { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "./styles.css"
import { ContNavBar, ContHeader, HeaderToggle, ContModal, BtnClose } from "../../theme/styleds"
import logoFolia from "../../assets/images/icons/logo_folia_header.svg";
import logoCompany from "../../assets/images/icons/logoCompany.jpg";

const Sidebar = ({ handleOpenLoginModal }) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const theme = localStorage.getItem("theme")
    const user = JSON.parse(localStorage.getItem('user'))
    const nameMail = user ? user.email.split("@")[0] : ""

    useEffect(() => {
        const linkColor = document.querySelectorAll('.nav_link')
        linkColor.forEach(l => l.classList.remove('active'))
        if (window.location.pathname === "/DashHome" || window.location.pathname === "/Home") {
            document.getElementById('nav-bar').style.display = "none"
        }
    }, []);

    const toggleSidebar = () => {
        const contSidePanelInfo = document.getElementById('contSidePanelInfo')
        const sidePanelInfo = document.getElementById('sidePanelInfo')

        if (contSidePanelInfo) {
            switch (contSidePanelInfo.style.width) {
                case "600px":
                    contSidePanelInfo.style.width = "0px";
                    // contSidePanelInfo.style.right = "0px";
                    sidePanelInfo.style.display = "none";
                    break;
                default:
                    contSidePanelInfo.style.width = "600px";
                    // contSidePanelInfo.style.right = "0px";
                    sidePanelInfo.style.display = "block";
                    break;
            }
        }
    }

    const toggleSidebarOne = () => {
        toggleSidebar()
        const contPanePredio = document.getElementById('contPanePredio')
        switch (contPanePredio.style.display) {
            case "":
            case "none":
                contPanePredio.style.display = "block";
                break;
            default:
                contPanePredio.style.display = "none";
                break;
        }
    }

    const handleNavigate = () => {
        const data = state.data;
        navigate('/DashHome', { state: { data } });
    };

    const toggleSidebarTwo = () => {
        toggleSidebar()
        const contPanelListFolders = document.getElementById('contPanelListFolders')
        switch (contPanelListFolders.style.display) {
            case "":
            case "none":
                contPanelListFolders.style.display = "block";
                break;
            default:
                contPanelListFolders.style.display = "none";
                break;
        }
    }

    const toggleSidebarThree = () => {
        toggleSidebar()
        const contPanelListPredios = document.getElementById('contPanelListPredios')
        switch (contPanelListPredios.style.display) {
            case "":
            case "none":
                contPanelListPredios.style.display = "block";
                break;
            default:
                contPanelListPredios.style.display = "none";
                break;
        }
    }

    const toggleSidebarFour = () => {
        toggleSidebar()
        const contPanelListDetailPredio = document.getElementById('contPanelListDetailPredio')
        switch (contPanelListDetailPredio.style.display) {
            case "":
            case "none":
                contPanelListDetailPredio.style.display = "block";
                break;
            default:
                contPanelListDetailPredio.style.display = "none";
                break;
        }
    }

    function handleMail() {
        window.open('mailto:folia@dreamgis.com');
    }



    return (
        <>
            <ContHeader className="header" id="header">
                <div className="row">
                    <div id="contLogos" className="col-3 d-flex flex-wrap text-center">
                        <img src={logoFolia} alt="Logo Folia" className="folia-logo" />
                    </div>
                    <div id="contMenu" className="col-6 text-center d-flex align-items-center justify-content-center">
                        {/*<a className="links-header" href="#"><strong className="header-title folia-font-xs">SOBRE FOLIA</strong>*/}
                        {/*<a className="links-header" href="#">*/}
                            <a href="https://www.frontiertechhub.org/insights" target="_blank" rel="noopener noreferrer"><strong className="header-title folia-font-xs">NOTICIAS</strong></a>
                        {/*<a className="links-header" href="#">*/}<strong className="header-title folia-font-xs" onClick={handleMail} style={{ cursor: 'pointer' }}>CONTÁCTANOS</strong>
                    </div>
                    <div className="col-3 d-flex justify-content-end">
                        <div id="contLangSelect">
                            <div className="cursor-action langSelect text-center d-flex align-items-center folia-font-xs"><span>ES</span></div>
                            <div className="cursor-action langNotSelect text-center d-flex align-items-center folia-font-xs"><span>ENG</span></div>
                        </div>

                        {state ? (
                            <>
                                <div id="contLogoDos" className="cursor-action text-center">
                                    <i className='bx bx-image-alt'></i>
                                </div>
                                <div id="contNotificaciones" className="cursor-action text-center">
                                    <i className='bx bx-bell-plus'></i>
                                </div>
                                <div className="dropdown">
                                    <button id="btnMenuZonaUsuario" className="my-0 btn btn-secondary dropdown-toggle"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        ZONA USUARIO
                                    </button>
                                    <ul className="pb-4 dropdown-menu">
                                        <li>
                                            <div className="cont-company-info">
                                                <img src={logoCompany} alt="Imagen perfil" className="company-logo" />
                                            </div>
                                        </li>
                                        <li className="ms-4">
                                            <strong className="ms-3 d-block">{state.data.name}</strong>
                                            <strong className="ms-3 d-block">{state.data.email}</strong>
                                        </li>
                                        <li className="ms-4"><a className="dropdown-item" href="#">Consulta con tu asesor</a></li>
                                        <li className="ms-4"><a className="dropdown-item" href="#">Guía de uso de Folia</a></li>
                                        <li className="ms-4"><a className="dropdown-item" href={process.env.REACT_APP_PATH}>Cerrar sesión</a></li>
                                    </ul>
                                </div>
                            </>
                        ) :
                            <div id="contZonaUsuario" className="cursor-action text-center folia-button
                                folia-button-loud-blue" onClick={() => handleOpenLoginModal()}>
                                Ingresar
                            </div>
                        }
                    </div>
                </div>
            </ContHeader >
            <ContNavBar className="l-navbar" id="nav-bar">
                {/* <div className="secod-nav"></div> */}
                <nav className="nav">
                    <div className="nav_list">
                        {/*<a id="nav-link-home" className="nav_link" title="Inicio">
                            <i className='bx bx-home-alt-2 nav_icon'></i>
                            <span className="nav_name"></span>
                        </a>*/}
                        <a onClick={toggleSidebarTwo} rel="noreferrer" className="nav_link" title="Tablero de Control">
                            <i className='bx bx-dock-left nav_icon'></i>
                            <span className="nav_name"></span>
                        </a>
                        {/*<a rel="noreferrer" className="nav_link" title="Tablero de Control">
                            <i className='bx bx-clipboard nav_icon'></i>
                            <span className="nav_name"></span>
                        </a>
                        <a rel="noreferrer" className="nav_link" title="SharePoint">
                            <i className='bx bx-bell nav_icon'></i>
                            <span className="nav_name"></span>
                        </a>*/}
                        <a rel="noreferrer"
                            className="nav_link" title="Dash" onClick={handleNavigate}>
                            <i className='bx bxs-dashboard nav_icon'></i>
                            <span className="nav_name"></span>
                        </a>
                    </div>
                </nav>
            </ContNavBar>

            <div className="modal fade" id="infoUserModal" tabIndex="-1" aria-labelledby="infoUserModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <ContModal className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="infoUserModalLabel">Información del usuario</h5>
                            <BtnClose type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <i className='bx bx-x nav_icon'></i>
                            </BtnClose>
                        </div>
                        <div className="modal-body">
                            <h3 className="mb-4">{user?.name} {user?.lastname}</h3>
                            <div>
                                <strong>Correo</strong>
                                <p>{user?.email}</p>
                            </div>
                            <div>
                                <strong>Rol</strong>
                                <p>{user?.rol}</p>
                            </div>
                        </div>
                    </ContModal >
                </div >
            </div >
        </>
    );
}

export default Sidebar;