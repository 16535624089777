import React from 'react';
import gifLoader from '../../assets/images/loading.gif';
import './loadingModal.css';

function LoadingModal() {
    return (
        <div className="modal-overlay">         
            <img
              src={gifLoader}
              alt="Cargando"
              className='loading-image'
            />
        </div>
      );
}

export default LoadingModal;