import React, { useState, useEffect, useContext } from "react";
import { AccountContext } from './Account'
import ChangePassword from './ChangePassword'
import ChangeEmail from './ChangeNickname'

const Settings = () => {
    const { getSession } = useContext(AccountContext)
    const [loggedIn, setLoggedIn] = useState(false)

    useEffect(() => {
        getSession()
            .then(() => { setLoggedIn(true) })
            .catch(() => { setLoggedIn(false) });
    }, [])

    return (
        <div>
            {loggedIn && (<>
                <h2>Ajustes</h2>
                <ChangePassword />
                <ChangeEmail />
            </>)}
        </div>
    )
}

export default Settings