import React, { useState, useContext } from "react";
import { AccountContext } from '../Account'
import "./style.css"; // Estilos del componente
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

const ChangePassword = () => {
    const [password, setPassword] = useState("")
    const [newNickname, setNewNickname] = useState("")
    const { getSession, authenticate } = useContext(AccountContext)

    const onSubmit = (e) => {
        e.preventDefault();
        getSession().then(({ user, email }) => {
            authenticate(email, password)
                .then(() => {
                    const attributes = [new CognitoUserAttribute({ Name: "nickname", Value: newNickname })]
                    
                    user.updateAttributes(attributes, (err, result) => {
                        if (err) { console.error(err); }
                        else { console.log("Correo actualizado: ", result) }
                    })
                })
                .catch(err => {
                    console.error("Error al iniciar sesion:", err)
                })
        }).catch(e => {
            console.log(e);
        });
    }

    const toggleShowPassword = (idInput, idEye) => {
        const passwordInput = document.getElementById(idInput);
        const togglePassword = document.getElementById(idEye);

        if (passwordInput.type === "password") {
            passwordInput.type = "text";
            togglePassword.classList.remove("fa-eye-slash");
            togglePassword.classList.add("fa-eye");
        } else {
            passwordInput.type = "password";
            togglePassword.classList.remove("fa-eye");
            togglePassword.classList.add("fa-eye-slash");
        }
    }

    return (
        <div className="card">
            <h2 className="text-center mb-4">Actualizar Usuario</h2>
            <form className="form-card" onSubmit={onSubmit}>
                <div className="row justify-content-between text-left">
                    <div className="form-group col-md-12 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="newNickname">Usuario nuevo<span className="text-danger"> *</span></label>
                        <input type="text" id="newNickname" name="newNickname" placeholder=""
                            className="form-control" value={newNickname} onChange={(e) => setNewNickname(e.target.value)} />
                    </div>
                </div>
                <div className="row justify-content-between text-left">
                    <div className="form-group col-md-12 flex-column d-flex">
                        <label className="form-control-label px-3" htmlFor="passwordNewNickname">Contraseña<span className="text-danger"> *</span></label>
                        <div className="input-group" id="show_hide_password">
                            <input type="password" id="passwordNewNickname" name="passwordNewNickname" placeholder=""
                                className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <div id="contTogglePassword" className="input-group-addon">
                                <i id="togglePasswordNewNickname" className="fa fa-eye-slash" aria-hidden="true"
                                    onClick={() => toggleShowPassword("passwordNewNickname", "togglePasswordNewNickname")}></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="text-center mt-4">
                        <button type="submit" className="btn btn-success">Cambiar usuario</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ChangePassword