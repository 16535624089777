import React, { createContext,useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js"
import Pool from "../../middlewares/UserPool";

const AccountContext = createContext();

const Account = (props) => {
    const [userAuth, setUserAuth] = useState(null);
    const [passwordUser, setPasswordUser] = useState(null);
    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession(async (err, session) => {
                    if (err) { reject() }
                    else {
                        const attributes = await new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) { reject() }
                                else {
                                    const results = {}
                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute
                                        results[Name] = Value
                                    }
                                    resolve(results)
                                }
                            })
                        })
                        resolve({user, ...session, ...attributes})
                    }
                })
            } else { reject("Sin usuario activo") }
        })
    }

    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool })
            setPasswordUser(Password)
            const authDetails = new AuthenticationDetails({ Username, Password })
            
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    setUserAuth(data);
                    resolve(data)
                },
                onFailure: (err) => {
                    reject(err)
                },
                newPasswordRequired: (data) => {
                    setUserAuth(data);
                    data.newPass = true;
                    resolve(data)
                },
            })
        })
    }

    const changePass = async (userMail, newPassword) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({ userMail, Pool })
            const password = 'Folia2023$';
            const authDetails = new AuthenticationDetails({ userMail, password })

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    user.changePassword(password, newPassword, (err, result) => {
                        if (err) {
                          console.error(err);
                        } else {
                          console.log('Contraseña cambiada con éxito', result);
                        }
                      });
                },
                onFailure: (err) => {
                    reject(err)
                },
                newPasswordRequired: (data) => {
                    setUserAuth(data);
                    resolve(data)
                },
            })
        })
    }

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) { user.signOut() }
    }

    return (
        <AccountContext.Provider value={{ userAuth, passwordUser, authenticate, getSession, changePass, logout }}>
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext }