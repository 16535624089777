import styled from "styled-components";

export const Input = styled.input`
    color: ${({ theme }) => theme.colors.text} !important;
    background-color: ${({ theme }) => theme.colors.background} !important;
    border: 1px solid ${({ theme }) => theme.colors.visualIdenGrayOne} !important;
    &:focus {
        color: ${({ theme }) => theme.colors.text} !important;
        background-color: ${({ theme }) => theme.colors.background} !important;
        border-color: ${({ theme }) => theme.colors.visualIdenGrayThree} !important;
        box-shadow: 0 0 0 0.25rem rgb(55 90 127 / 25 %) !important;
    }
`;

export const Checkbox = styled.input`
    color: ${({ theme }) => theme.colors.text} !important;
    background-color: ${({ theme }) => theme.colors.background} !important;
    border: 1px solid ${({ theme }) => theme.colors.visualIdenGrayOne} !important;
    &:focus {
        color: ${({ theme }) => theme.colors.text} !important;
        background-color: ${({ theme }) => theme.colors.background} !important;
        border-color: ${({ theme }) => theme.colors.visualIdenGrayThree} !important;
        box-shadow: 0 0 0 0.25rem rgb(55 90 127 / 25 %) !important;
    }
    &:checked[type=checkbox] {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23${({ theme }) => theme.colors.textCheckbox}%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
    }
`;

export const InputDate = styled.input`
    color: ${({ theme }) => theme.colors.text} !important;
    background-color: ${({ theme }) => theme.colors.background} !important;
    border: 1px solid ${({ theme }) => theme.colors.visualIdenGrayOne} !important;
    color-scheme: ${({ theme }) => theme.colors.nameTheme};
    &:focus {
        color: ${({ theme }) => theme.colors.text} !important;
        background-color: ${({ theme }) => theme.colors.background} !important;
        border-color: ${({ theme }) => theme.colors.visualIdenGrayThree} !important;
        box-shadow: 0 0 0 0.25rem rgb(55 90 127 / 25 %) !important;
    }
`;

export const TextArea = styled.textarea`
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.visualIdenGrayOne};
    &:focus {
        color: ${({ theme }) => theme.colors.text};
        background-color: ${({ theme }) => theme.colors.background};
        border-color: ${({ theme }) => theme.colors.visualIdenGrayThree};
        box-shadow: 0 0 0 0.25rem rgb(55 90 127 / 25 %);
    }
`;

export const Select = styled.select`
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.visualIdenGrayOne};
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23${({ theme }) => theme.colors.textCheckbox}%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    &:focus {
        color: ${({ theme }) => theme.colors.text};
        background-color: ${({ theme }) => theme.colors.background};
        border-color: ${({ theme }) => theme.colors.visualIdenGrayThree};
        box-shadow: 0 0 0 0.25rem rgb(55 90 127 / 25 %);
    }
`;

export const ContAddMany = styled.div`
    background: ${({ theme }) => theme.colors.visualIdenGrayTwo};
    padding      : 10px;
    margin-top   : 5px;
    margin-bottom: 10px;
`;

export const BtnAddElem = styled.button`
    background: ${({ theme }) => theme.colors.visualIdenPrimary};
    color: #FFFFFF;
    &:hover {
        background-color: ${({ theme }) => theme.colors.sideBar} !important;
        color: #FFFFFF !important;
    }
`;

export const ToggleButton = styled.button`
  --toggle-width: 60px;
  --toggle-height: 30px;
  --toggle-padding: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1rem;
  line-height: 1;
  width: var(--toggle-width);
  height: var(--toggle-height);
  padding: var(--toggle-padding);
  border: 0;
  border-radius: calc(var(--toggle-width) / 2);
  cursor: pointer;
  background: ${({ theme }) => theme.colors.colorBgToggle};
  transition: background 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  &:focus {
    outline-offset: 5px;
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:hover {
    box-shadow: 0 0 5px 2px ${({ theme }) => theme.colors.colorBgToggle};
  },
`;

export const ToggleThumb = styled.span`
  position: absolute;
  top: var(--toggle-padding);
  left: var(--toggle-padding);
  width: calc(var(--toggle-height) - (var(--toggle-padding) * 2));
  height: calc(var(--toggle-height) - (var(--toggle-padding) * 2));
  border-radius: 50%;
  background: white;
  transition: transform 0.25s ease-in-out;
  transform: ${(p) =>
        p.activeTheme === "dark"
            ? "translate3d(calc(var(--toggle-width) - var(--toggle-height)), 0, 0)"
            : "none"};
`;

export const ContModal = styled.div`
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.background};
`;

export const BtnClose = styled.button`
    width: 1.7em;
    height: 1.7em;
    color: ${({ theme }) => theme.colors.text};
    background: none;
    &:hover {
        color: ${({ theme }) => theme.colors.text};
    }
`;

export const BtnLogin = styled.button`
    background: ${({ theme }) => theme.colors.visualIdenPrimary};
    color: #FFFFFF;
    &:hover {
        background-color: ${({ theme }) => theme.colors.sideBar} !important;
        color: #FFFFFF !important;
    }
`;

export const ContFooter = styled.div`
  background: ${({ theme }) => theme.colors.visualIdenGrayTwo};
`;

export const ContNavBar = styled.div`
    background-color: ${({ theme }) => theme.colors.visualIdenSecOne};
`;

export const ContHeader = styled.header`
    background-color: ${({ theme }) => theme.colors.visualIdenGrayTwo};
`;

export const HeaderToggle = styled.div`
    color: ${({ theme }) => theme.colors.text};
`;

export const PageLink = styled.button`
    color: ${({ theme }) => theme.colors.text};
    background-color: transparent;
    border: none;
    border-radius: 0px !important;
    &:hover {
        color: ${({ theme }) => theme.colors.text};
        background-color: ${({ theme }) => theme.colors.hoverTable};
        border: none;
    }
    &:focus {
        color: ${({ theme }) => theme.colors.text};
        background-color: ${({ theme }) => theme.colors.hoverTable};
        border: none;
        box-shadow: none;
    }
`;

export const ContItemModalInfo = styled.div`
    background: ${({ theme }) => theme.colors.visualIdenGrayTwo};
    padding      : 5px 10px;
    height: 100%;
`;

export const InputFile = styled.input`
    color: ${({ theme }) => theme.colors.text} !important;
    background-color: ${({ theme }) => theme.colors.background} !important;
    border: 1px solid ${({ theme }) => theme.colors.visualIdenGrayOne} !important;
    &:focus {
        color: ${({ theme }) => theme.colors.text} !important;
        background-color: ${({ theme }) => theme.colors.background} !important;
        border-color: ${({ theme }) => theme.colors.visualIdenGrayThree} !important;
        box-shadow: 0 0 0 0.25rem rgb(55 90 127 / 25 %) !important;
    }
    ::file-selector-button {
        color: ${({ theme }) => theme.colors.text} !important;
        background-color: ${({ theme }) => theme.colors.visualIdenGrayTwo} !important;
        border: 1px solid ${({ theme }) => theme.colors.visualIdenGrayTwo} !important;
    }
`;