const addButton = (map, view, icon, position, title, tooltip, action) => {
    const customControl = view.Control.extend({
        options: { position: position },
        onAdd: () => {
            const container = view.DomUtil.create(
                "div", "leaflet-bar leaflet-control"
            );

            container.type = "button";
            container.title = title;
            container.label = tooltip;

            const link = view.DomUtil.create("a");
            link.classList.add("fa");
            link.classList.add(`fa-${icon}`);

            container.appendChild(link);
            container.style.cursor = "pointer";
            container.onclick = function () { action(); };

            return container;
        },
    });

    map.addControl(new customControl());
};

export { addButton };
