import axios from "axios";

export async function Petition(endpoint, options = {}) {
  try {
    let response = await fetch(`${process.env.REACT_APP_URL_API}${endpoint}`, {
      method: options.method,
      headers: options.headers
    });
    if (response.ok) {
      const data = await response.json();
      if(options.type === "geographic"){
        return data.features;
      }else{
        return data;
      }
    } else {
      return {}
    }
  } catch (error) {
    return {}
  }
};
