import React, { useState, useContext } from "react";
import { AccountContext } from '../Account';
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "./style.css"; // Estilos del componente
import logoFolia from "../../../assets/images/icons/logo_folia_login.svg";

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [messageErrorModal, setMessageErrorModal] = useState(false);
    const { authenticate, passwordUser } = useContext(AccountContext)
    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();
        authenticate(email, password)
            .then(data => {
                /*if(data.newPass !== undefined){
                    // Redirigir a ChangePassword
                    navigate("/PureMap", { state: { data } });
                }*/
                localStorage.setItem('myData', data);
                navigate("/PureMap", { state: { data } });
            })
            .catch(err => {
                const errorMessage = err.message || err;
                if (errorMessage.includes('Incorrect username')) {
                    setShowErrorModal(true);
                    setMessageErrorModal(' Usuario y/o contraseña incorrectos. Por favor, inténtalo de nuevo.')
                }
                if(errorMessage.includes('User does not exist')){
                    setShowErrorModal(true);
                    setMessageErrorModal('El usuario no existe. Por favor, pongase en contacto con los administradores del sistema.')
                }
            })
    }

    const toggleShowPassword = () => {
        const passwordInput = document.getElementById("password");
        const togglePassword = document.getElementById("togglePassword");

        if (passwordInput.type === "password") {
            passwordInput.type = "text";
            togglePassword.classList.remove("fa-eye-slash");
            togglePassword.classList.add("fa-eye");
        } else {
            passwordInput.type = "password";
            togglePassword.classList.remove("fa-eye");
            togglePassword.classList.add("fa-eye-slash");
        }
    }

    const handleCloseErrorModal = () => setShowErrorModal(false);

    const handleNavigate = () => {
        navigate('/Home');
    };

    return (
        <div className="page-container">
            <div className="card">
                <div className="logo-container">
                    <img src={logoFolia} alt="Logo Folia" className="folia-logo ms-4" />
                </div>
                <form className="form-card" onSubmit={onSubmit}>
                    <div className="row justify-content-between text-left">
                        <div className="form-group col-md-12 flex-column d-flex">
                            <label className="form-control-label px-3" htmlFor="email">Correo<span className="text-danger"> *</span></label>
                            <input type="email" id="email" name="email" placeholder="micorreo@mail.com"
                                className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="row justify-content-between text-left">
                        <div className="form-group col-md-12 flex-column d-flex">
                            <label className="form-control-label px-3" htmlFor="password">Contraseña<span className="text-danger"> *</span></label>
                            <div className="input-group" id="show_hide_password">
                                <input type="password" id="password" name="password" placeholder=""
                                    className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <div id="contTogglePassword" className="input-group-addon">
                                    <i id="togglePassword" className="fa fa-eye-slash" aria-hidden="true" onClick={toggleShowPassword}></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="text-center mt-4">
                            <button type="submit" className="btn btn-success custom-button">Ingresar</button>
                        </div>
                        <div className="text-center mt-4">
                            <button onClick={handleNavigate} className="btn btn-success custom-button">Landing</button>
                        </div>
                    </div>
                </form>

                {/* Modal de error */}
                <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error de autenticación</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {messageErrorModal}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseErrorModal}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>            
        </div>
    )
}

export default Login