import React from 'react';
import { Route, Routes } from "react-router-dom";
import DashHome from "./domains/dashhome";
import Geovisor from "./domains/geovisor";
import Home from "./domains/auth/home";
import Signup from "./domains/auth/signup";
import Login from "./domains/auth/login";
import ChangePassword from "./domains/auth/changePassword";
import { ThemeProvider } from "styled-components";
import { ThemeToggle, GlobalStyles } from "./components"
import { useThemeMode, themes } from "./theme";
import logoPartners from "./assets/images/icons/logos_apoyo_login.svg";
import "./App.css";

function App() {
    const [theme, toggleTheme] = useThemeMode();

    return (
        <ThemeProvider theme={themes[theme]}>
            <GlobalStyles />
            <div id="fondo-con-imagen" className={window.location.pathname !== "/Home" ? '' : 'fondo-con-imagen-home'}>
                <ThemeToggle className="toggle-theme d-none" onClick={toggleTheme} />
                <div className="App">
                    <Routes basename={process.env.REACT_APP_PATH}>
                        <Route path={process.env.REACT_APP_PATH} element={<Home />} />
                        <Route path={process.env.REACT_APP_PATH + "Home"} element={<Home />} />
                        <Route path={process.env.REACT_APP_PATH + "DashHome"} element={<DashHome />} />
                        <Route path={process.env.REACT_APP_PATH + "PureMap"} element={<Geovisor />} />
                        <Route path={process.env.REACT_APP_PATH + "Auth/Signup"} element={<Signup />} />
                        <Route path={process.env.REACT_APP_PATH + "Auth/Login"} element={<Login />} />
                        <Route path={process.env.REACT_APP_PATH + "Auth/ChangePassword"} element={<ChangePassword />} />
                    </Routes>
                </div>
            </div>
            {/*window.location.pathname !== "/Home" ?
                <div className="partners-container">
                    <img src={logoPartners} alt="Logo Partners" className="partners-logo" />
                </div> : null*/}

        </ThemeProvider>
    );
}

export default App;