const themes = {
    light: {
        colors: {
            nameTheme: "light",
            background: "#ffffff",
            sideBar: "#ffffff",
            text: "#23292d",
            colorBgToggle: "#6699FF",
            visualIdenPrimary: "#FF8300",
            visualIdenSecOne: "#061437",
            visualIdenSecTwo: "#9DBEFF",
            visualIdenSecThree: "#6699FF",
            visualIdenGray: "#080808",
            visualIdenGrayOne: "#8a8a8a",
            visualIdenGrayTwo: "#ffffff",
            visualIdenGrayThree: "#e6e6e6",
            hoverTable: "rgba(0, 0, 0, 0.05)",
            textCheckbox: "23292d"
        },
    },
    dark: {
        colors: {
            nameTheme: "dark",
            background: "#1d2a35",
            sideBar: "#ffffff",
            text: "#373737",
            colorBgToggle: "#a9a9a9",
            visualIdenPrimary: "#FF8300",
            visualIdenSecOne: "#061437",
            visualIdenSecTwo: "#9DBEFF",
            visualIdenSecThree: "#6699FF",
            visualIdenGray: "#080808",
            visualIdenGrayOne: "#8a8a8a",
            visualIdenGrayTwo: "#e6e6e6",
            visualIdenGrayThree: "#e6e6e6",
            hoverTable: "#0d1721",
            textCheckbox: "FFFFFF"
        },
    },
};

export default themes;