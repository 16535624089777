const measure = (map, view, type, color = "#FF0000") => {
    view.Measure = {
        linearMeasurement: "Medir Longitud",
        areaMeasurement: "Medir Área",
        start: "Inicio",
        meter: "m",
        kilometer: "km",
        squareMeter: "m²",
        squareKilometers: "km²",
    };
    const measureAction = new view.MeasureAction(map, {
        model: type,
        color: color,
    });

    setTimeout(() => measureAction.enable(), 0);
};

export { measure };
