import { useState } from "react";
import { ToggleButton, ToggleThumb } from "../theme/styleds"

const ThemeToggle = (props) => {
    const [activeTheme, setActiveTheme] = useState(localStorage.getItem("theme"));
    const inactiveTheme = activeTheme === "light" ? "dark" : "light";

    const changeHandler = () => {
        setActiveTheme(inactiveTheme)
        props.onClick();
    }

    return (
        <div className={props.className}>
            <ToggleButton
                aria-label={`Cambiar al modo ${inactiveTheme === "light" ? "claro" : "oscuro"}`}
                title={`Cambiar al modo ${inactiveTheme === "light" ? "claro" : "oscuro"}`}
                type="button"
                onClick={() => changeHandler()}
            >
                <ToggleThumb activeTheme={activeTheme} />
                <span>🌙</span>
                <span>☀️</span>
            </ToggleButton>
        </div>
    );
};

export default ThemeToggle;