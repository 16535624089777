import React, { useEffect, useRef, useState } from "react";
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
import Home from '@arcgis/core/widgets/Home';
import LayerList from '@arcgis/core/widgets/LayerList';
import Legend from '@arcgis/core/widgets/Legend';
import esriConfig from '@arcgis/core/config';
import '@arcgis/core/assets/esri/themes/light/main.css'; // Importar el CSS de ArcGIS

const MapArcgis = React.forwardRef((props, ref) => {
    const [view, setView] = useState(null);
    const [webmap, setWebmap] = useState(null);
    const [mapLayers, setMapLayers] = useState({
        all: [],
        risk: ["Deforestación Presencia - Ausencia 2020 2023", "Área total Def 2020 2023 Municipio", "Proporción Def 2020 2023 Municipio"],
        legal: ["Predios VS Áreas Protegidas", "Predios VS Frontera Agricola"],
        evaluation: ["FOLIA Report Cero Def 2020 2023"]
    });

    useEffect(() => {
        if (!ref.current) return;

        // Establecer las credenciales mediante código
        esriConfig.portalUrl = 'https://dreamgis.maps.arcgis.com';
        esriConfig.request.useIdentity = true;

        // Crear una instancia del mapa web
        const webmap = new WebMap({
          portalItem: {
            id: 'd397afe301794797ab35a55ee00dbb48' // ID del mapa web
          }
        });
        setWebmap(webmap);

        // Crear una instancia de la vista del mapa
        const view = new MapView({
          map: webmap,
          container: ref.current,
          center: [-75.9711, 1.3392], // Coordenadas de San José del Fragua, Colombia
          zoom: 12,
          constraints: {
            minZoom: 6
          }
        });
        setView(view);

        // Agregar el widget de inicio (zoom home)
        const homeWidget = new Home({
          view: view
        });
        view.ui.add(homeWidget, {
          position: "top-left"
        });

        // Agregar el widget LayerList con acciones
        const layerList = new LayerList({
          view: view,
          listItemCreatedFunction: function(event) {
            const item = event.item;
            item.panel = {
              content: "legend",
              open: true
            };
          }
        });

        view.ui.add(layerList, {
          position: "top-right"
        });

        // Agregar el widget Legend
        const legend = new Legend({
          view: view
        });

        view.ui.add(legend, {
          position: "bottom-left"
        });

        // Esperar a que el mapa se cargue completamente antes de acceder a las capas
        webmap.when(() => {
          const allLayers = webmap.layers.map(layer => layer.title);
          setMapLayers(prevState => ({ ...prevState, all: allLayers }));
        });

        // Limpiar la vista y liberar recursos cuando el componente se desmonte
        return () => {
          if (view) {
            view.container = null;
          }
        };
    }, [ref]);

    const showLayers = (layerTitles) => {
      if (webmap) {
          webmap.layers.forEach(layer => {
              // Establecer la visibilidad de la capa según el título
              layer.visible = layerTitles.includes(layer.title);
              
          });
      }
  };

    return (
        <div style={{ position: 'relative', width: '100%', height: '97%' }}>
            <div ref={ref} style={{ width: '100%', height: '100%' }}></div>
            <div style={{ position: 'absolute', top: '10px', left: '180px', zIndex: 10 }}>
                <button onClick={() => showLayers(mapLayers.all)} style={{ padding: '10px', margin: '5px', background: 'white', border: '1px solid black' }}>Inicio</button>
                <button onClick={() => showLayers(mapLayers.risk)} style={{ padding: '10px', margin: '5px', background: 'white', border: '1px solid black' }}>Riesgo</button>
                <button onClick={() => showLayers(mapLayers.legal)} style={{ padding: '10px', margin: '5px', background: 'white', border: '1px solid black' }}>Legal</button>
                <button onClick={() => showLayers(mapLayers.evaluation)} style={{ padding: '10px', margin: '5px', background: 'white', border: '1px solid black' }}>Evaluación</button>
            </div>
        </div>
    );
});

export default MapArcgis;
